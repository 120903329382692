import AmanTooltip from '../../General/AmanTooltip/AmanTooltip'
import Truncator from '../../General/Truncator/Truncator'
import './info/GuestPageInfoTab.css'
const MobileKeyValueColumnItem = ({key1, value1, key2, value2}) => {
    const getTooltip = (key) => {
        if(key == "Language") {
            return <AmanTooltip id="language-tooltip" pos="top" text="Preferred communication language"/>
        } else if (key == "VIP Code") {
            return <AmanTooltip id="vipcode-tootlip" pos="top" text="Qualitatif segmentation.<br/>It can be changed by Hotel.<br/>Please refer to SOP"/>
        } else if (key == "Join Date") {
            return  <AmanTooltip id="joindedate-tootlip" pos="top" text="Date in which the Guest has<br/>first been registered in the Aman<br/>database"/>
        }else if (key == "ORS ID") {
            return  <AmanTooltip id="orsid-tootlip" pos="top" text="Guest unique Opera ID"/>
        } return null
    }
    return(
        <>
            <div className="info-value-item">
                <div className="div">
                    <div className="text-wrapper-2">{key1}{getTooltip(key1)}</div>
                    <div className="div-2">
                        <div className="text-wrapper-3">{value1}</div>
                    </div>
                </div>
                <div className="div-3">
                    <div className="text-wrapper-2">{key2}{getTooltip(key2)}</div>
                    <div className="div-4">
                        <div className="text-wrapper-3"><Truncator text={value2} length={24}/></div>
                    </div>
                </div>
            </div>
        </>
    )
}
const StayColumnItem = ({key1, value1, key2, value2}) => {
    return(
        <>
            <div className="info-value-item stay-column-item">
                <div className="div">
                    <div className="text-wrapper-2">{key1}</div>
                    <div className="div-2">
                        <div className="text-wrapper-2">{value1}</div>
                    </div>
                </div>
                <div className="div-3">
                    <div className="text-wrapper-2">{key2}</div>
                    <div className="div-4">
                        <div className="text-wrapper-2">{value2}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
const ValueColumnItem = ({key1, value1, key2, value2}) => {
    return(
        <>
            <div className="info-value-item">
                <div className="div">
                    <div className="text-wrapper-2">{key1}</div>
                    <div className="div-2">
                        <div className="text-wrapper-2">{value1}</div>
                    </div>
                </div>
                <div className="div-3">
                    <div className="text-wrapper-2">{key2}</div>
                    <div className="div-4">
                        <div className="text-wrapper-3">{value2}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {MobileKeyValueColumnItem, StayColumnItem, ValueColumnItem};