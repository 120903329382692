import React, { useState, useEffect } from 'react';
import { UserData } from './Data';
import PieChart from '../PieChart/PieChart';

function GuestValuePieChart({spending:guestSpending}) {
    const categoryTotals = {};

    guestSpending.forEach((data) => {
        const { category, totalValue } = data;

        if (categoryTotals[category]) {
            categoryTotals[category] += parseFloat(totalValue); 
        } else {
            categoryTotals[category] = parseFloat(totalValue); 
        }
    });

    const borderColor ='#F3EEE7';
    const textColor = '#313131';

    const color1 = '#313131';
    const color2 = '#938F8D';
    const color3 = '#545454';
    const color4 = '#8B8B8B';
    const color5 = '#656565';
    const transparency = '80'

    const userData = {
      labels: Object.keys(categoryTotals).map(label => capitalizeFirstLetter(label)),
      datasets: [{
        backgroundColor: [color1+transparency, color2+transparency, color3+transparency, color4+transparency, color5+transparency], 
        data: Object.values(categoryTotals),
        borderColor: [color1, color2, color3, color4, color5],
        borderWidth: 2,
        datalabels: {
          anchor: 'center'
        }
      }]
    };
    const total = userData.datasets[0].data.reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    },0);
    const options = {
      legend: { display: true, position: "right" },

      plugins:{
      datalabels: {
        labels: {
          title:{
            font: {
              weight: 'bold',
            }
          }
        },
        display: function(context) {
          return true;
        },
        formatter: function(value, context) {
          let label = userData.labels[context.dataIndex];
          let percentage = Math.round(10000*value/total)/100;
          if(percentage < 5){
            return ''
          }
          return label+"\n"+percentage+"%";
        },

      }
    },
      tooltips: {
        backgroundColor: "#5a6e7f",
      },
    }

    return (
      <PieChart chartData={userData} options={options} />
    );
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default GuestValuePieChart;
