import {AuthenticatedTemplate, MsalProvider, useMsal} from '@azure/msal-react';
import {PageLayout} from './components/PageLayout';
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import { Footer, NavBar } from './components/Sections';
import { HomePage } from './Pages';
import { Guest, Hotel } from './views';

import './styles/Global.css';
import './styles/App.css';
import * as Sentry from "@sentry/react";

import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {NoRoleText} from "./languages/en.jsx";

// Create a client
const queryClient = new QueryClient()

const App = () => {

    const {instance} = useMsal();
    const activeAccount = instance.getActiveAccount();

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <div className="App">
                    <NavBar />
                    <div className="content-container">
                        <AuthenticatedTemplate>
                            {activeAccount && activeAccount.idTokenClaims.roles?.length > 0 ? (
                                <div className="page-container">
                                    <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        <Route path="/guest" element={<Guest />} />
                                        <Route path="/hotel" element={<Hotel />} />
                                    </Routes>
                                </div>
                            ) : (
                                <div className="page-container">
                                        <div className="home-page-wrapper">
                                            <span className="no-access">{NoRoleText.explanation}</span>
                                        </div>
                                </div>
                                )
                            }
                            <Footer />
                        </AuthenticatedTemplate>
                    </div>
                </div>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};

const Home = ({instance}) => {
    return (<MsalProvider instance={instance}>
        <PageLayout>
            <App />
        </PageLayout>
    </MsalProvider>);
};

export default Sentry.withProfiler(Home);
