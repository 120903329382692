import React from 'react';
import './Truncator.css';


const Truncator = ({text,length}) => {
    if (text?.length > length) {
        return (
            
                <span className='truncator-ellipsis' data-text={text}>{`${text.slice(0, length)}...`}</span>
            
        )
    } else {
        return text
    }
}
export default Truncator;























