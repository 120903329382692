import React, { useState } from 'react';
import '../General/Loader/Loader.css'

import './SearchGuestResults.css'
import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import {isoToDate, dateToIso} from '../../utils/utilities'
import {callApiWithToken} from "../../utils/fetch.js";
import {useMsal} from "@azure/msal-react";


const SearchWidget = ({searchParams, setSearchParams, setView}) => {
    const {instance} = useMsal();

    const[keyword, setKeyword] = useState(searchParams.keyword);

    React.useEffect(() => {
        const updateData = setTimeout(() => {
          setSearchParams({...searchParams, keyword: keyword})
        }, 2000)
    
        return () => clearTimeout(updateData)
      }, [keyword])

    const fetchFilterData = () => callApiWithToken(instance,'/snowflake/filter-data')
      .then((res) => res );
      const {
          isLoading,
          isError,
          error,
          data,
          isFetching,
          isPreviousData,
        } = useQuery(['filter-data'], () => fetchFilterData(), { keepPreviousData : true, staleTime: 2*60*60*1000 })
      
      let vipData = data?.GUEST_VIP_STATUS?.map((x)=>{ return {value: x, label: x}});
      let nationalityData = data?.GUEST_NATIONALITY?.map((x)=>{ return {value: x.code, label: x.name}});
      let propertiesData = data?.PROFILE_PROPERTY?.map((x)=>{ return {value: x.PROPERTY_CODE, label: x.PROPERTY_NAME}});
      let checkedInData = [{value: true, label: "Yes"},{value: false, label: "No"}]
  

    return(
        <div className="search-widget-container">
            <input className='search-widget-text-input magnifier-icon'
                               value={keyword}
                               onChange={(e)=>setKeyword(e.target.value)}
                               placeholder="Search across First name, Last name and Email" />
            <div className='search-widget-elems'>
                <Select
                      placeholder={"Hotel"}
                      value={searchParams.hotel == '' ? '' : propertiesData?.filter((x)=>searchParams.hotel==x.value)[0]}
                      onChange={(e)=>{let val = e ? e.value:""; setSearchParams({...searchParams, hotel: val})}}
                      options={propertiesData}
                      noOptionsMessage={({inputValue}) => `No results for ${inputValue}`}
                      isClearable
                      escapeClearsValue
                      backspaceRemovesValue
                      isSearchable
                      className='search-widget-select-input'
                      classNamePrefix="search-widget-select-input"    
                  />
                  <Select
                      placeholder={"Check in"}
                      value={searchParams.checkedIn === '' ? '' : checkedInData.filter((x)=>searchParams.checkedIn===x.value)[0]}
                      onChange={(e)=>{let val = e ? e.value:""; setSearchParams({...searchParams, checkedIn: val})}}
                      options={checkedInData}
                      noOptionsMessage={({inputValue}) => `No results for ${inputValue}`}
                      isClearable
                      escapeClearsValue
                      backspaceRemovesValue
                      isSearchable
                      className='search-widget-select-input'
                      classNamePrefix="search-widget-select-input"    
                  />
            </div>
            <div className='search-widget-elems'>
                
              <DatePicker wrapperClassName='search-widget-datepicker-wrapper'
                          className='search-widget-date-input'
                          selected={isoToDate(searchParams.arrivalDate)}
                          onChange={(v)=>setSearchParams({...searchParams, arrivalDate: dateToIso(v)})}
                          placeholderText='Arrival date'
                          dateFormat="dd/MM/yyyy" />
              <DatePicker wrapperClassName='search-widget-datepicker-wrapper'
                          className='search-widget-date-input'
                          selected={isoToDate(searchParams.departureDate)}
                          onChange={(v)=>setSearchParams({...searchParams, departureDate: dateToIso(v)})}
                          placeholderText='Departure date'
                          dateFormat="dd/MM/yyyy" />
            </div>
            <div className='guest-result-mobile-controls'>
                <button className='guest-result-mobile-button' onClick={() => setView('search')}>More search fields</button>
            </div>
        </div>
    )
}
export default SearchWidget;























