import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import {msalConfig} from './auth/authConfig';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';
import 'react-tooltip/dist/react-tooltip.css';

const msalInstance = new PublicClientApplication(msalConfig);

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
        new Sentry.BrowserTracing({
            // See docs for support of different versions of variation of react router
            // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes
            ),
        }),
    ],


    tracePropagationTargets: ["localhost", /^https:\/\/aman360-be-[a-z]{3}.apps.massiverocket\.com/],

    tracesSampleRate: 0.1
});

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    const account = msalInstance.getAllAccounts()[0];
    msalInstance.setActiveAccount(account);
    Sentry.setUser({ username: account.username });
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
        Sentry.setUser({ username: account.username });
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App instance={msalInstance} />);
