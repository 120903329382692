import React from 'react';
import { GuestPage_Summary } from '../../../../languages/en';

import './SatisfactionModalContent.css';

const data = [
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Feb 2023', checkout: '9 Feb 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
  { checkin: '1 Jan 2023', checkout: '9 Jan 2023', hotel: 'Amanruya', rating: 4.6, comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.' },
];

const SatisfactionModalContent = () => {
  return (
    <div className="satisfaction-modal-content-container">
      <div className="satisfaction-modal-content-row-heading">
        <h2 className="satisfaction-modal-content-heading">{GuestPage_Summary.Modal_SatisfactionHeading}</h2>
      </div>
      <div className="satisfaction-modal-content-row-hl satisfaction-modal-content-row-hl-top">
        <div className="satisfaction-modal-content-hl" />
      </div>
      <table className="satisfaction-modal-table">
        <thead>
          <tr className="satisfaction-modal-table-row satisfaction-modal-table-header">
            <th className="satisfaction-modal-table-cell-header">{GuestPage_Summary.satisfactionModal_header_checkin}</th>
            <th className="satisfaction-modal-table-cell-header">{GuestPage_Summary.satisfactionModal_header_checkout}</th>
            <th className="satisfaction-modal-table-cell-header">{GuestPage_Summary.satisfactionModal_header_hotel}</th>
            <th className="satisfaction-modal-table-cell-header">{GuestPage_Summary.satisfactionModal_header_rating}</th>
            <th className="satisfaction-modal-table-cell-header comment-column">{GuestPage_Summary.satisfactionModal_header_guestComment}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <tr className="satisfaction-modal-table-row" key={index}>
              <td className="satisfaction-modal-table-cell">{entry.checkin}</td>
              <td className="satisfaction-modal-table-cell">{entry.checkout}</td>
              <td className="satisfaction-modal-table-cell">{entry.hotel}</td>
              <td className="satisfaction-modal-table-cell">{entry.rating}</td>
              <td className="satisfaction-modal-table-cell comment-column">{entry.comment}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="satisfaction-modal-content-row-hl satisfaction-modal-content-row-hl-bottom">
        <div className="satisfaction-modal-content-hl" />
      </div>
    </div>
  );
};

export default SatisfactionModalContent;