import React from 'react';

import './SearchGuestResults.css'
import { useMsal } from '@azure/msal-react';
import { callApiWithToken } from '../../utils/fetch';
import { useQuery } from '@tanstack/react-query';

import '../General/Loader/Loader.css'
import Truncator from '../General/Truncator/Truncator';

const GuestExpandedDetails = ({user}) => {

    const { instance } = useMsal();
    const fetchUserInfo = () => callApiWithToken(instance,`/user/${user.USER_ID}/info`).then((res) => res);
  
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['userInfo', user.USER_ID], () => fetchUserInfo(), { keepPreviousData : true, staleTime: 2*60*1000 })

    const retrieveLanguage = (dataProp) => dataProp ? dataProp : "N/A";
    const retrieveCountry = (dataProp) => dataProp ? dataProp : "N/A";
    const retrieveTxt = (dataProp) => dataProp ? dataProp : "N/A";

    const retrieveDate = (dataProp) => dataProp ? dataProp : "N/A";

      
    return isLoading || isFetching?(
            <div className='mobile-row-loader'><span className='loader'></span></div>
        ):isError?(
            <div>{error.message}</div>
        ):( 
        <div className='guest-mobile-expanded-wrapper'>
            <div className='guest-mobile-expanded-row'>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        Birth Date
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        {retrieveDate(data?.dateOfBirth)}
                    </div>
                </div>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        Language
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        {retrieveLanguage(data?.language)}
                    </div>
                </div>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        Country
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        {retrieveCountry(data?.country)}
                    </div>
                </div>
            </div>
            <div className='guest-mobile-expanded-row'>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        VIP Code
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        {retrieveTxt(user?.GUEST_VIP_CODE)}
                    </div>
                </div>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        ORS ID
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        <Truncator text={data?.orsId.join(',')} length={24}/>
                    </div>
                </div>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        RFMD Tag
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        {retrieveTxt(data?.rfmdTag)}
                    </div>
                </div>
            </div>
            <div className='guest-mobile-expanded-row'>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        Last stay
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        {retrieveDate(data?.lastStay)}
                    </div>
                </div>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        Joined
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        {retrieveDate(data?.joinDate)}
                    </div>
                </div>
                <div className='guest-mobile-elem-wrapper'>
                    <div className='guest-mobile-row-elem-title'>
                        WealthX Score
                    </div>
                    <div className='guest-mobile-row-elem-txt'>
                        {retrieveTxt(data?.wealthXScore)}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GuestExpandedDetails;