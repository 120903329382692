// // SatisfactionScore.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './SatisfactionScore.css'; 

const SatisfactionScore = ({ value, text, onClick, tooltipText }) => {
  const getStarFill = (index) => {
    if (value <= 19) {
      return index === 0 ? 'var(--red)' : 'var(--transparent-semi)';
    } else if (value >= 20 && value <= 39) {
      return index <= 1 ? 'var(--red)' : 'var(--transparent-semi)';
    } else if (value >= 40 && value <= 59) {
      return index <= 2 ? 'var(--red)' : 'var(--transparent-semi)';
    } else if (value >= 60 && value <= 79) {
      return index <= 3 ? 'var(--orange)' : 'var(--transparent-semi)';
    } else if (value >= 80 && value <= 100) {
      return 'var(--green)';
    } else {
      return 'var(--transparent-semi)';
    }
  };

  return (
    <div className="satisfaction-score-wrapper" onClick={onClick} title={tooltipText}>
      <div className="guest-satisfaction-score">
        {[0, 1, 2, 3, 4].map((index) => (
          <span
            key={index}
            className={`star${index}`}
            style={{ color: getStarFill(index) }}
          >
            &#9733;
          </span>
        ))}
      </div>
    </div>
  );
};

SatisfactionScore.propTypes = {
  value: PropTypes.number,
  text: PropTypes.string,
};

export default SatisfactionScore;


// const satisfactionValue = 100;
// const satisfactionText = 'Satisfaction';
// <SatisfactionScore value={satisfactionValue} text={satisfactionText  || 'Satisfaction'} />
