import React from 'react';

import './ToggleSwitch.css'

const ToggleSwitch = ({className, checked, setChecked}) => {
    const handleClickedYes = () => {
        if(checked === true){
            setChecked('')
        }else{
            setChecked(true)
        }
    }
    const handleClickedNo = () => {
        if(checked === false){
            setChecked('')
        }else{
            setChecked(false)
        }
    }
    
    return(
        <div className={'toggle-switch-wrapper ' + className}>
            <div className='toggle-switch-title'>Checked-in</div>
            <div className={(checked === true ? 'toggle-switch-checked ':'')+'toggle-switch-clickable'} onClick={handleClickedYes}>Yes</div>
            <div className={(checked === false ? 'toggle-switch-checked ':'')+'toggle-switch-clickable'} onClick={handleClickedNo}>No</div>
        </div>
    )
}
export default ToggleSwitch;