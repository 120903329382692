import React, { useState } from 'react';
import '../GuestProfileDesktop.css'
import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { callApiWithToken } from '../../../utils/fetch';
import { datePrettyDDMMYYY} from '../../../utils/utilities';

const DesktopStays = ({guest}) => {
    const { instance } = useMsal();
    const fetchStay = () => callApiWithToken(instance, `/user/${guest.USER_ID}/stay`).then((res)=>res);
    
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['user_stay',guest.USER_ID], () => fetchStay(), { keepPreviousData : true, staleTime: 2*60*1000 })


    return isFetching || isLoading ? (
        <div className='loader-container'><div className='loader'></div></div>
    ): isError ?(
        <div>Error {error.message}</div>
    ):
    (
        <React.Fragment>
            <div className='desktop-stays-box'>
                <div className='stay-topic-title'>
                    Next Stay
                </div>
                <div className='next-stay-subtitle'>
                    <div>Booking No: {data.nextStay.confirmationNumber ? data.nextStay.confirmationNumber : "N/A"}</div>
                    <div className='booking-status'>
                        <div>Status:</div>
                        <div style={{color: "#15803D"}}>{data.nextStay.status ? data.nextStay.status : "N/A"}</div>
                    </div>
                </div>
                <div className='next-stay-details'>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            Hotel
                        </div>
                        <div className='detail-value'>
                            {data.nextStay.hotel ? data.nextStay.hotel : "N/A"}
                        </div>
                    </div>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            Check In
                        </div>
                        <div className='detail-value'>
                            {data.nextStay.checkIn ? datePrettyDDMMYYY(data.nextStay.checkIn) : "N/A"}
                        </div>
                    </div>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            Check Out
                        </div>
                        <div className='detail-value'>
                            {data.nextStay.checkOut ? datePrettyDDMMYYY(data.nextStay.checkOut) : "N/A"}
                        </div>
                    </div>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            Guests
                        </div>
                        <div className='detail-value'>
                            {data.nextStay.guests ? data.nextStay.guests : "N/A"}
                        </div>
                    </div>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            Rate
                        </div>
                        <div className='detail-value'>
                            {data.nextStay.rates ? data.nextStay.rates : "N/A"}
                        </div>
                    </div>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            ADR
                        </div>
                        <div className='detail-value'>
                            {data.nextStay.adr ? data.nextStay.adr : "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='desktop-stays-box'>
                <div className='stay-topic-title'>
                    All stays
                </div>
                <div className='all-stays-header'>
                    <div className='header-label'>
                        Check In
                    </div>
                    <div className='header-label'>
                        Check Out
                    </div>
                    <div className='header-label'>
                        Property
                    </div>
                    <div className='header-label'>
                        Nights
                    </div>
                    <div className='header-label'>
                        Reservation No
                    </div>
                    <div className='header-label'>
                        Rate
                    </div>
                </div>
                <div className='overflowing-stays'>
                    {data.reservations.map((res,index)=>
                        <div className='all-stay-row' key={index}>
                            <div className='row-item'>
                                {datePrettyDDMMYYY(res.checkIn)}
                            </div>
                            <div className='row-item'>
                                {datePrettyDDMMYYY(res.checkOut)}
                            </div>
                            <div className='row-item'>
                                {res.property}
                            </div>
                            <div className='row-item'>
                                {res.nights}
                            </div>
                            <div className='row-item'>
                                {res.reservationNo}
                            </div>
                            <div className='row-item'>
                                {res.rate}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}
export default DesktopStays;