import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './FilterDate.css';

const FilterDate = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(value ? new Date(value) : null); // Initialize with value

  const datePickerRef = useRef(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date ? date.toISOString().split('T')[0] : ''; // Handle null date
    onChange(formattedDate);
    setIsOpen(false);
  };

  const handleDocumentClick = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    setSelectedDate(value ? new Date(value) : null)
  },[value])

  const toggleDatePicker = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="filter-date" ref={datePickerRef}>
      <div
        className={`date-button ${selectedDate ? 'date-button-selected' : 'date-button-unselected'}`}
        onClick={toggleDatePicker}
      >
        <input
          className="date-input"
          type="date"
          value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
          onChange={handleDateChange}
        />
      </div>

      {isOpen && (
        <div className="date-picker">
          <DatePicker selected={selectedDate} onChange={handleDateChange} dateFormat="DD/MM/YYYY" inline />
        </div>
      )}
    </div>
  );
};

export default FilterDate;
