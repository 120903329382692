import React from 'react';
import PropTypes from 'prop-types';

import './AppButton.css';

const AppButton = ({ onClick, children }) => {
  return (
    <button className="app-button" onClick={onClick}>
      {children}
    </button>
  );
};

AppButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default AppButton;
