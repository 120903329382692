import guestImage from "../../../assets/images/guest-image.png";
import React from "react";
import '../GuestProfile.css'
import '../GuestProfileMobile.css'

const GuestProfileInitial = ({ guest }) => {
    const displayEmail = guest.EMAIL_ADDRESS ? guest.EMAIL_ADDRESS : 'N/A';
    const displayPhone = guest.PHONE ? guest.PHONE : 'N/A';

    return (
        <>
            <div className="guest-profile-frame">
                <div className="text-wrapper">
                    <div>{guest.TITLE} {guest.GUEST_FIRST_NAME} {guest.GUEST_LAST_NAME}</div>
                </div>
                <div className="div-3">
                    <img className="image" alt="Image" src={guestImage} />
                    <div className="div-4">
                        <div className="div-2">
                            <div className="text-wrapper-3">Email:</div>
                            <div className="text-wrapper-4 truncate-text">{displayEmail}</div>
                        </div>
                        <div className="div-2">
                            <div className="text-wrapper-3">Phone:</div>
                            <div className="text-wrapper-4 truncate-text">{displayPhone}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GuestProfileInitial;
