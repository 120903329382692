import axios from 'axios';
import {silentRequest} from "../auth/authConfig";

export const callApiWithToken = async (msalInstance, apiEndpoint) => {
    let accessToken;

    try {
        // this will either retrieve the token we already have in cache (fromCache=true), or fetch a new one
        const tokenResponse = await msalInstance.acquireTokenSilentAsync(silentRequest, msalInstance.getActiveAccount());
        accessToken = tokenResponse.idToken;
    } catch (error) {
        console.error(error);
        throw new Error('Cannot validate authorization');
    }

    if (!accessToken || accessToken === '') throw new Error('No tokens found');

    const headers = {
        Authorization: `Bearer ${accessToken}`,
    };

    const url = import.meta.env.VITE_SERVER_PORT + apiEndpoint

    try {
        const response = await axios.get(url, {headers: headers});
        return response.data;
    } catch (error) {
        console.error(error);
        throw new Error('Failed to make API call');
    }
};

export const downloadExcelWithToken = async (msalInstance, apiEndpoint) => {
    let accessToken;

    try {
        // this will either retrieve the token we already have in cache (fromCache=true), or fetch a new one
        const tokenResponse = await msalInstance.acquireTokenSilentAsync(silentRequest, msalInstance.getActiveAccount());
        accessToken = tokenResponse.idToken;
    } catch (error) {
        console.error(error);
        throw new Error('Cannot validate authorization');
    }

    if (!accessToken || accessToken === '') throw new Error('No tokens found');

    const headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    };

    const urlToBackend = import.meta.env.VITE_SERVER_PORT + apiEndpoint

    try {
        const response = await axios.get(urlToBackend, {headers: headers});
        var mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
        var userInp = response.data;
        var a = document.createElement('a');
        a.href = mediaType+userInp;
        a.download = `report_${Date.now()}.xlsx`;
        a.textContent = '.';
        a.style="display:none;"
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } catch (error) {
        console.error(error);
        throw new Error('Failed to download');
    }
};