import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { callApiWithToken } from '../../../utils/fetch';
import { HomePageText } from '../../../languages/en';

// Components
import { FilterDate, FilterHeading, FilterDropdown } from '../../SearchFilter';
import { AppButton, HorizontalLine, Loader } from '../../General';

import './ArrivalReport.css';
import { useMsal } from "@azure/msal-react";

const ArrivalReport = ({ onClose }) => {
  const { instance } = useMsal();
  const [profileProperties, setProfileProperties] = useState([]);

  const [hotel, setHotel] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [departureDate, setDepartureDate] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [property, setProperty] = useState('');
  const [userData, setUserData] = useState([]);
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Define isLoading state here

  // State to track whether all input fields have values
  const isAllFieldsValid = property.trim() !== '' && startDate.trim() !== '' && endDate.trim() !== '';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApiWithToken(instance, '/snowflake/filter-data');
        setProfileProperties(response.PROFILE_PROPERTY);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    if (profileProperties.length === 0) {
      fetchData();
    }
  }, []);

  const propertyOptions = profileProperties.map((property) => ({
    value: property.PROPERTY_CODE,
    label: property.PROPERTY_NAME,
  }));

  const navigate = useNavigate();

  const handleArrivalReport = async (e) => {
    e.preventDefault();

    // Check if departure date is before arrival date
    if (new Date(departureDate) <= new Date(arrivalDate)) {
      alert(HomePageText.DepartureDateLessThanArrivalDate);
      return;
    }

    try {
      setIsLoading(true);
      // todo: use formik or something similar to handle form
      const params = {
        property,
        startDate,
        endDate
      }
      const response = await callApiWithToken(
        instance,
        `/arrival-report?${new URLSearchParams(params).toString()}`
      );
      setUserData(response);
      setIsSearchClicked(true);

      navigate('/arrival-report', { state: response }); //CHANGE THIS LINE TO DOWNLOAD THE EXCEL FILE

      // Close the modal when the search button is clicked
      onClose();
    } catch (error) {
      console.error('Error:', error);
      if (error.message === 'Failed to make API call') {
        // Display an alert to the user for the specific error
        alert(HomePageText.ArrivalReportErrorApiText);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="main-filter-container">
      <div className="search-filter-container">
        <div className="filter-container">
          <FilterHeading text={HomePageText.ArrivalReportHeading} />
          <HorizontalLine />

          {/* NOTIFICATION */}
          <div className={`row-container row-notification ${isAllFieldsValid ? 'enabled' : 'disabled'}`}>
            <p className="notification">
              {HomePageText.ArrivalReportNotification}
            </p>
          </div>

          {/* HOTELS */}
          <div className="row-container">
            <div className="row-heading">
              <FilterHeading
                text={HomePageText.ArrivalReportHotelsHeader}
              />
            </div>
            <FilterDropdown
              options={propertyOptions}
              defaultText={HomePageText.ArrivalReportHotelsDefaultText}
              value={property}
              onChange={setProperty}
            />
          </div>

          {/* ARRIVAL AND DEPARTURE */}
          <div className="row-container">
            <div className="row-container left-row arrival-row">
              <div className="row-heading">
                <FilterHeading
                  text={HomePageText.ArrivalReportArrivalHeader}
                />
              </div>
              <FilterDate
                value={startDate}
                onChange={setStartDate}
              />
            </div>
            <div className="row-container right-row">
              <div className="row-heading">
                <FilterHeading
                  text={HomePageText.ArrivalReportDepartureHeader}
                />
              </div>
              <FilterDate
                value={endDate}
                onChange={setEndDate}
                min={startDate}
              />
            </div>
          </div>

        </div>

        <div className={`button-filter-container ${isAllFieldsValid ? 'enabled' : 'disabled'}`} >
          {isLoading ? (
            <div className="loader-container">
              <span className="loader"></span>
            </div>
          ) : (
            <Link to="/arrival-report">
              <AppButton className="submit-button" onClick={handleArrivalReport} disabled={!isAllFieldsValid}>{HomePageText.ArrivalReportButtonText}</AppButton>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArrivalReport;
