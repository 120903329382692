import React, { useState } from 'react';
import './GuestPageStayTab.css'
import {StayColumnItem} from "../MobileKeyValueColumnItem.jsx";
import {datePrettyDDMMYYY, displayRate} from "../../../../utils/utilities.js";
const StayColumn = ({ stay }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };
    const displayNAIfNull = (value) => {
        return value ?? 'N/A';
    };

    return (
    <div className={`stay-info`}>
        <div className={`stay-column ${expanded ? 'expanded' : ''}`} onClick={toggleExpanded}>
            <div className="stay-column-left clickable">
                <div className="stay-property">{stay.property}</div>
                <div className="stay-check-in"> <span className="stay-date">C/I: </span>{datePrettyDDMMYYY(stay.checkIn)}</div>
            </div>
            <div className="stay-column-right clickable">
                <div className="stay-nights">{stay.nights} nights</div>
                <div className="stay-check-out"><span className="stay-date">C/O: </span>{datePrettyDDMMYYY(stay.checkOut)}</div>
            </div>
            <div className={`chevron-icon ${expanded ? 'open' : ''}`}/>
        </div>
        {expanded &&
            <StayColumnItem key1={"Reservation No."} value1={displayNAIfNull(stay.reservationNo)} key2={"Rate"} value2={displayRate(stay.rate)}  />}
    </div>
);
};

export default StayColumn;
