import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom'
import { HomePageText } from '../../languages/en'

import './HomePage.css';

import Guest360Image from '../../assets/images/landing-guest360.png';
import ReportImage from '../../assets/images/landing-reporting.png';
import TransactionalCampaignsImage from '../../assets/images/landing-transactional-campaigns.png'
import { SearchModal } from '../../components/SearchFilter';
import { ArrivalReport } from '../../components/Sections';
import LandingButton from '../../components/LandingButton/LandingButton';
import { useMsal } from '@azure/msal-react';

const HomePage = () => {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const {instance} = useMsal();
  const activeAccount = instance.getActiveAccount();
  const roles = activeAccount.idTokenClaims.roles || ["corporate_restricted"]

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setIsReportModalOpen(false);
  };

  const handleHomeButtonClickNextGuest = () => {
    window.open('https://z56.nextguest.app/guestcommunication/MessagesOverview.aspx', '_blank', 'noopener,noreferrer,nofollow');
  };

  const handleHomeButtonClickGuest360 = () => {
    navigate('/guest');
  };

  const handleHomeButtonClickReport = () => {
    navigate('/hotel');
  };


  return (
    <div className="home-page-wrapper">
        <LandingButton
          onClick={handleHomeButtonClickNextGuest}
          backgroundImage={TransactionalCampaignsImage}
          text="Transactional Campaign"
          bgPosMobile="56%"
        />
        <LandingButton
          onClick={handleHomeButtonClickGuest360}
          backgroundImage={Guest360Image}
          text="Aman Guest 360"
          bgPosMobile="83%"
        />
        {!roles.includes("corporate_restricted") &&
        <LandingButton
          onClick={handleHomeButtonClickReport}
          backgroundImage={ReportImage}
          text="Generate Arrival report"
          bgPosMobile="75%"
        />}


      {isReportModalOpen && (
        <SearchModal onClose={handleCloseModal} >
         <ArrivalReport className="arrival-report-modal" />
        </SearchModal>
      )}


    </div>
  );
};

export default HomePage;
