import React, { useState } from 'react';
import MediaQuery from 'react-responsive'
import './GuestProfile.css'
import GuestProfileDesktop from './GuestProfileDesktop';
import GuestProfileMobile from './GuestProfileMobile';

const GuestProfile = ({guest, resetGuest}) => {
    return (
        <React.Fragment>
            <MediaQuery maxWidth={900}>
                <GuestProfileMobile guest={guest} resetGuest={resetGuest}/>
            </MediaQuery>
            <MediaQuery minWidth={900}>
                <GuestProfileDesktop guest={guest} resetGuest={resetGuest}/>
            </MediaQuery>
        </React.Fragment>
    )
}
export default GuestProfile;