import React from 'react'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register (ArcElement, Tooltip, Legend);

function PieChart({ chartData, options }) {
  return <Pie data={chartData} plugins={[ChartDataLabels]} options={options}></Pie>
}

export default PieChart



