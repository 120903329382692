import React from 'react';

import './SearchGuestResults.css'
import GuestExpandedDetails from './GuestExpandedDetails';

const GuestMobileRow = ({user, setExpandedUser, expandedUser, setGuest}) => {
    const truncate = (text,size) => {
        if(text?.length > size){
            return (
                <span>{text.slice(0,size)}...</span>
            )
        } else {
            return text;
        }
    }
    const expandUser = () => {
        if(expandedUser){
            setExpandedUser(null)
        } else {
            setExpandedUser(user.USER_ID)
        }
    }

    const openUser = () => {
        setGuest(user)
    }

    return (
        <React.Fragment>
            <div className="guest-mobile-wrapper">
                <div className="guest-mobile-left clickable" onClick={openUser}>
                    <div className='guest-mobile-name'>
                        {user.GUEST_FIRST_NAME}&nbsp;{user.GUEST_LAST_NAME}
                    </div>
                    <div className='guest-mobile-email'>
                        {user.EMAIL_ADDRESS}
                    </div>
                </div>
                <div className='guest-mobile-right clickable' onClick={openUser}>
                    <div>{user.GUEST_VIP_CODE}</div>
                    <div>{user.COUNTRY}</div>
                </div>
                <div className={`chevron-icon ${expandedUser ? 'open' : ''}`} onClick={expandUser}></div>
            </div>
            {expandedUser && <GuestExpandedDetails user={user}/>}
        </React.Fragment>
    )
}
export default GuestMobileRow;























