import {MobileKeyValueColumnItem} from "../MobileKeyValueColumnItem.jsx";
import {callApiWithToken} from "../../../../utils/fetch.js";
import {useQuery} from "@tanstack/react-query";
import React from "react";
import {useMsal} from "@azure/msal-react";
import StayColumn from "./StayColumn.jsx";
import {datePrettyDDMMYYY, displayNAIfNull, displayRate} from "../../../../utils/utilities.js";

const GuestPageStayTab = ({userId}) => {
    const {instance} = useMsal();

    const userStayInfo = () => callApiWithToken(instance, `/user/${userId}/stay`).then((res) => res);

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
    } = useQuery(['user_stay', userId], () => userStayInfo(), {keepPreviousData: true, staleTime: 2 * 60 * 1000});
    return isLoading || isFetching ? (
        <div className='guest-profile-loader'><span className='gp-loader'></span></div>
    ) : isError ? (
        <div>{error.message}</div>
    ) : (
        <>

            {data.nextStay.checkIn !== null || data.nextStay.checkOut !== null || data.nextStay.hotel !== null || data.nextStay.confirmationNumber !== null || data.nextStay.status !== null || data.nextStay.guests !== null || data.nextStay.rates !== null || data.nextStay.adr !== null ? (
                <div>
                    <div className="guest-profile-tab-frame">
                        <div className="text-wrapper">Next Stay</div>
                        <MobileKeyValueColumnItem key1={"Booking No."}
                                                  value1={displayNAIfNull(data.nextStay.confirmationNumber)}
                                                  key2={"Status"} value2={displayNAIfNull(data.nextStay.status)}/>
                        <MobileKeyValueColumnItem key1={"Hotel"} value1={displayNAIfNull(data.nextStay.hotel)}
                                                  key2={"Guests"} value2={displayNAIfNull(data.nextStay.guests)}/>
                        <MobileKeyValueColumnItem key1={"Check In"}
                                                  value1={displayNAIfNull(datePrettyDDMMYYY(data.nextStay.checkIn))}
                                                  key2={"Check Out"}
                                                  value2={displayNAIfNull(datePrettyDDMMYYY(data.nextStay.checkOut))}/>
                        <MobileKeyValueColumnItem key1={"Rate"} value1={displayRate(data.nextStay.rates)} key2={"ADR"}
                                                  value2={displayNAIfNull(data.nextStay.adr)}/>
                    </div>
                </div>
            ) : (
                <div className="guest-profile-tab-frame">
                    <div className="text-wrapper">Next Stay</div>
                    <div className="data-not-available">
                        No future bookings
                    </div>

                </div>
            )}

            <div>
                <div className="guest-profile-tab-frame">
                    <div className="text-wrapper">All Stays</div>
                    {data.reservations.map((stay, index) => (
                        <StayColumn key={index} stay={stay}/>
                    ))}
                </div>
            </div>
        </>
    );
};

export default GuestPageStayTab;