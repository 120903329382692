import React, { useState } from 'react';
import SearchGuestForm from '../../components/SearchGuestForm/SearchGuestForm';
import SearchGuestResults from '../../components/SearchGuestResults/SearchGuestResults';
const initialView = "search";
const initialSearchParams = {
    firstname:'',
    lastname:'',
    email:'',
    phone:'',
    dob:'',
    nationality:'',
    vipStatus:'',
    hotel:'',
    checkedIn:'',
    arrivalDate:'',
    departureDate:'',
    keyword:''
}



const Guest = () => {
    const [searchParams, setSearchParams] = useState(initialSearchParams);
    const [totalCount, setCount] = useState(0);
    const [view, setView] = useState(initialView);
    const isSearchView = () => view === "search";
    const isResultsView = () => view === "results" || view === "profile"
    const clearSearch = () => {
        setSearchParams(initialSearchParams);
        setCount(0);
    };
    
    return(
        <React.Fragment>
            {isSearchView() && <SearchGuestForm searchParams={searchParams} 
                                                setSearchParams={setSearchParams}
                                                clear={clearSearch} 
                                                setView={setView} />}
            {isResultsView() && <SearchGuestResults searchParams={searchParams}
                                                    setSearchParams={setSearchParams}
                                                    setView={setView} 
                                                    totalCount={totalCount}
                                                    setCount={setCount}/>}
        </React.Fragment>
    )
}
export default Guest;