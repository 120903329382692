import './GuestPageInfoTab.css'
import '../../../SearchGuestResults/SearchGuestResults.css'
import MobileKeyValueRowItem from "../MobileKeyValueRowItem.jsx";
import {MobileKeyValueColumnItem} from "../MobileKeyValueColumnItem.jsx";
import {callApiWithToken} from "../../../../utils/fetch.js";
import {useQuery} from "@tanstack/react-query";
import {useMsal} from "@azure/msal-react";
import React, {useState} from "react";
import {
    SatisfactionModal,
    SatisfactionModalContent,
    SatisfactionScore
} from "../../../UserDetails/InnerComponents/index.jsx";
import {GuestPage_Summary} from "../../../../languages/en.jsx";
import {datePrettyDDMMYYY} from "../../../../utils/utilities.js";

const displayNAIfNull = (value) => {
    return value ?? 'N/A';
};


const GuestPageInfoTab = ({guest}) => {
    const {instance} = useMsal();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [satisfactionValue, setSatisfactionValue] = useState(90);

    const fetchUserInfo = () => callApiWithToken(instance, `/user/${guest.USER_ID}/info`).then((res) => res);
    const fetchUserOtherInfo = () => callApiWithToken(instance, `/user/${guest.USER_ID}/other`).then((res) => res);

    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
    } = useQuery(['user_info', guest.USER_ID], () => fetchUserInfo(), {keepPreviousData: true, staleTime: 2 * 60 * 1000})

    return isLoading || isFetching ? (
        <div className='guest-profile-loader'><span className='gp-loader'></span></div>
    ) : isError ? (
        <div>{error.message}</div>
    ) : (
        <>
            <div>
                <div className="guest-profile-tab-frame">
                    <div className="text-wrapper">Personal Information</div>
                    <MobileKeyValueColumnItem key1={"Language"} value1={displayNAIfNull(data.language)} key2={"Nationality"} value2={displayNAIfNull(data.country)}/>
                    <MobileKeyValueColumnItem key1={"Birthdate"} value1={displayNAIfNull(datePrettyDDMMYYY(data.dateOfBirth))} key2={"ORS ID"} value2={displayNAIfNull(data?.orsId.join(','))}/>
                    <MobileKeyValueColumnItem key1={"VIP Code"} value1={displayNAIfNull(data.vipCode)} key2={"Join Date"} value2={displayNAIfNull(datePrettyDDMMYYY(data.joinDate))}/>
                </div>
            </div>
            <div>
                <div className="guest-profile-tab-frame">
                    <div className="text-wrapper">Address</div>
                    <MobileKeyValueRowItem label={"Country"} value={displayNAIfNull(data.country)}/>
                    <MobileKeyValueRowItem label={"City"} value={displayNAIfNull(data.city)}/>
                    <MobileKeyValueRowItem label={"Street"} value={displayNAIfNull(data.address)}/>
                    <MobileKeyValueRowItem label={"Zip Code"} value={displayNAIfNull(data.zipCode)}/>
                </div>
            </div>
            <div>
                <div className="guest-profile-tab-frame">
                    <div className="text-wrapper">Personal Preferences</div>
                    <MobileKeyValueRowItem label={"Rooms"} value={displayNAIfNull(fetchUserOtherInfo.RoomsPreferences)}/>
                    <MobileKeyValueRowItem label={"F&B"} value={displayNAIfNull(fetchUserOtherInfo.FnBPreferences)}/>
                    <MobileKeyValueRowItem label={"Spa"} value={displayNAIfNull(fetchUserOtherInfo.SPAPreferences)}/>
                    <MobileKeyValueRowItem label={"Retail"} value={displayNAIfNull(fetchUserOtherInfo.RetailPreferences)}/>
                </div>
            </div>
            <div>
                <div className="guest-profile-tab-frame">
                    <div className="text-wrapper">Newsletters</div>
                    <MobileKeyValueRowItem label={"Marketing Consent"} value={displayNAIfNull(data.marketingConsent)}/>
                    <MobileKeyValueRowItem label={"Newsletters"} value={displayNAIfNull(data.newsletter)}/>
                </div>
            </div>
        </>
    )
}

export default GuestPageInfoTab;
