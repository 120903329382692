import MobileKeyValueRowItem from "../MobileKeyValueRowItem.jsx";
import {useMsal} from "@azure/msal-react";
import {callApiWithToken} from "../../../../utils/fetch.js";
import {useQuery} from "@tanstack/react-query";
import React from "react";
import {ValueColumnItem} from "../MobileKeyValueColumnItem.jsx";
import {datePrettyDDMMYYY, displayNAIfNull, displayRate} from "../../../../utils/utilities.js";

const GuestPageValueTab = ({userId}) => {
    const {instance} = useMsal();
    const userValueInfo = () => callApiWithToken(instance, `/user/${userId}/value`).then((res) => res);
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
    } = useQuery(['user_value', userId], () => userValueInfo(), {keepPreviousData: true, staleTime: 2 * 60 * 1000})

    return isLoading || isFetching ? (
        <div className='guest-profile-loader'><span className='gp-loader'></span></div>
    ) : isError ? (
        <div>{error.message}</div>
    ) : (
        <>
            <div>
                <div className="guest-profile-tab-frame">
                    <div className="text-wrapper">Value</div>
                    <MobileKeyValueRowItem label={"Lifetime value"}
                                           value={displayRate(data.lifetimeValue)}/>
                    <MobileKeyValueRowItem label={"Total nights"} value={displayNAIfNull(data.nightsCount)}/>
                    <MobileKeyValueRowItem label={"Total hotels visited"}
                                           value={displayNAIfNull(data.visitedHotelsCount)}/>
                </div>
            </div>
            <div>
                <div className="guest-profile-tab-frame">
                    <div className="text-wrapper">All Spend</div>
                    {data.all.length > 0 ? (data.all.map((spend, index) =>
                            (<ValueColumnItem key={index} key1={spend.category}
                                              value1={`— ${datePrettyDDMMYYY(spend.date)}`} key2={spend.hotel}
                                              value2={displayRate(spend.totalValue)}/>))
                    ) : (
                        <div className="data-not-available">No spends available</div>
                    )}
                </div>

            </div>

        </>
    )
}

export default GuestPageValueTab;