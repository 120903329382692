import React from 'react';

import './HorizontalLine.css';

const HorizontalLine = () => {
  return (
    <div className="horizontal-line"></div>
  );
};

export default HorizontalLine;

// <HorizontalLine />