import React from 'react';
import GuestProfileInitial from "./Mobile/GuestProfileInitial.jsx";
import GuestProfileTabLayout from "./Mobile/GuestProfileTabLayout.jsx";

const GuestProfileMobile = ({guest, resetGuest}) => {
    return (
        <React.Fragment>
                <div className="back-arrow" onClick={resetGuest}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Frame" clipPath="url(#clip0_1_2622)"><path id="Vector" d="M20.25 12H3.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path id="Vector_2" d="M10.5 5.25L3.75 12L10.5 18.75" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></g><defs><clipPath id="clip0_1_2622"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
                </div>
                <GuestProfileInitial guest={guest} />
                <GuestProfileTabLayout guest={guest}/>
        </React.Fragment>
    )
}
export default GuestProfileMobile;