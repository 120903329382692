import React from 'react';
import PropTypes from 'prop-types';

import './GuestImage.css';

const GuestImage = ({ imageUrl, tooltipText }) => {
    return (
         <div className="guest-image-container" title={tooltipText}>
            <img src={imageUrl} alt="Guest image" className="guest-image" />
        </div>
    );
};

GuestImage.propTypes = {
    imageUrl: PropTypes.string.isRequired,
};

export default GuestImage;


// const guestImageUrl = 'url_of_your_image.jpg'
// <GuestImage imageUrl={guestImageUrl} />