import React, { useState } from 'react';
import MediaQuery from 'react-responsive'
import './SearchGuestResults.css'
import GuestResultsDesktop from './GuestResultsDesktop';
import GuestResultsMobile from './GuestResultsMobile';
import GuestProfile from '../GuestProfile/GuestProfile';

const SearchGuestResults = ({searchParams,setSearchParams, setView, totalCount, setCount}) => {
    const [guest, setGuest] = useState(null);
    const resetGuest = () => setGuest(null);

    return guest ? (<GuestProfile guest={guest} resetGuest={resetGuest}/>):(
        <React.Fragment>
            <MediaQuery maxWidth={900}>
                <GuestResultsMobile searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    setView={setView}
                                    totalCount={totalCount}
                                    setCount={setCount}
                                    setGuest={setGuest}/>
            </MediaQuery>
            <MediaQuery minWidth={900}>
                <GuestResultsDesktop searchParams={searchParams}
                                    setView={setView}
                                    totalCount={totalCount}
                                    setCount={setCount}
                                    setGuest={setGuest}/>
            </MediaQuery>
        </React.Fragment>
    )
}
export default SearchGuestResults;