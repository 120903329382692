import React from 'react';
import DatePicker from "react-datepicker";
import {useMsal} from "@azure/msal-react";
import {callApiWithToken} from '../../utils/fetch'
import { useQuery } from '@tanstack/react-query'

import HeadingFrame from '../HeadingFrame/HeadingFrame';
import Select from 'react-select';
import { dateToIso, isoToDate } from '../../utils/utilities';

import './SearchGuestForm.css'
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const SearchGuestForm = ({searchParams, setSearchParams, clear, setView}) => {
    const { instance } = useMsal();
    const isFormValid = () => (
        searchParams.firstname.trim() !== ''  ||
        searchParams.lastname.trim() !== ''  ||
        searchParams.email.trim() !== ''  ||
        searchParams.phone.trim() !== '' ||
        searchParams.dob.trim() !== '' ||
        searchParams.nationality.trim() !== '' ||
        searchParams.vipStatus.trim() !== '' ||
        searchParams.hotel.trim() !== '' ||
        typeof searchParams.checkedIn == 'boolean' ||
        searchParams.arrivalDate.trim() !== '' ||
        searchParams.departureDate.trim() !== '' ||
        searchParams.keyword.trim() !== '' 
        )
    
    const fetchFilterData = () => callApiWithToken(instance,'/snowflake/filter-data')
    .then((res) => res );
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['filter-data'], () => fetchFilterData(), { keepPreviousData : true, staleTime: 2*60*60*1000 })
    
    let vipData = data?.GUEST_VIP_STATUS?.map((x)=>{ return {value: x, label: x}});
    let nationalityData = data?.GUEST_NATIONALITY?.map((x)=>{ return {value: x.code, label: x.name}});
    let propertiesData = data?.PROFILE_PROPERTY?.map((x)=>{ return {value: x.PROPERTY_CODE, label: x.PROPERTY_NAME}});
    
    const handleEnter = (e) => {
        if(e.key == "Enter" && isFormValid()) {
            setView('results')
        }
    }

    return(
        <React.Fragment>
            <HeadingFrame>
                Search Aman Guests
            </HeadingFrame>
            <div className='guest-search-form-wrapper' onKeyUp={(e)=>handleEnter(e)}>
                <div className='search-group-row'>
                    <div className='search-group-title'>Keyword search</div>
                    <div className='search-group-elems'>
                        <input className='search-form-text-input magnifier-icon'
                               value={searchParams.keyword}
                               onChange={(e)=>setSearchParams({...searchParams, keyword: e.target.value})}
                               placeholder="Search across First name, Last name and Email" />
                    </div>
                </div>
                <div className='search-group-row'>
                    <div className='search-group-title'>Search by personal info</div>
                    <div className='search-group-elems'>
                        <input className='search-form-text-input'
                               placeholder="First name"
                               value={searchParams.firstname}
                               onChange={(e)=>setSearchParams({...searchParams, firstname: e.target.value})} />
                        <input className='search-form-text-input'
                               placeholder="Last name"
                               value={searchParams.lastname}
                               onChange={(e)=>setSearchParams({...searchParams, lastname: e.target.value})} />
                        <DatePicker wrapperClassName='search-form-datepicker-wrapper' 
                                    className='search-form-date-input'
                                    selected={isoToDate(searchParams.dob)}
                                    onChange={(v)=>setSearchParams({...searchParams, dob: dateToIso(v)})}
                                    placeholderText='Birth date'
                                    dateFormat="dd/MM/yyyy" />
                        <Select
                            placeholder={"Choose VIP status"}
                            value={searchParams.vipStatus == '' ? '' : vipData?.filter((x)=>searchParams.vipStatus==x.value)[0]}
                            onChange={(e)=>{let val = e ? e.value:""; setSearchParams({...searchParams, vipStatus: val})}}
                            options={vipData}
                            noOptionsMessage={({inputValue}) => `No results for ${inputValue}`}
                            isClearable
                            escapeClearsValue
                            backspaceRemovesValue
                            isSearchable
                            className='search-form-select-input'
                            classNamePrefix="search-form-select-input"
                        />
                    </div>
                </div>
                <div className='search-group-row bottom-separation'>
                    <div className='search-group-title'>Search by contact info</div>
                    <div className='search-group-elems'>
                        <input className='search-form-text-input'
                               placeholder="Phone number"
                               value={searchParams.phone}
                               onChange={(e)=>setSearchParams({...searchParams, phone: e.target.value})} />
                        <input className='search-form-text-input'
                               placeholder="Email"
                               value={searchParams.email}
                               onChange={(e)=>setSearchParams({...searchParams, email: e.target.value})} />
                    </div>
                </div>
                <div className='search-group-row'>
                    <div className='search-group-title'>Filters</div>
                    <div className='search-group-elems'>
                        <Select
                            placeholder={"Choose hotel"}
                            value={searchParams.hotel == '' ? '' : propertiesData?.filter((x)=>searchParams.hotel==x.value)[0]}
                            onChange={(e)=>{let val = e ? e.value:""; setSearchParams({...searchParams, hotel: val})}}
                            options={propertiesData}
                            noOptionsMessage={({inputValue}) => `No results for ${inputValue}`}
                            isClearable
                            escapeClearsValue
                            backspaceRemovesValue
                            isSearchable
                            className='search-form-select-input'
                            classNamePrefix="search-form-select-input"    
                        />
                        <Select
                            placeholder={"Choose nationality"}
                            value={searchParams.nationality == '' ? '' : nationalityData?.filter((x)=>searchParams.nationality==x.value)[0]}
                            onChange={(e)=>{let val = e ? e.value:""; setSearchParams({...searchParams, nationality: val})}}
                            options={nationalityData}
                            noOptionsMessage={({inputValue}) => `No results for ${inputValue}`}
                            isClearable
                            escapeClearsValue
                            backspaceRemovesValue
                            isSearchable
                            className='search-form-select-input'
                            classNamePrefix="search-form-select-input"
                        />
                    </div>
                    <div className='search-group-elems'>
                        <DatePicker wrapperClassName='search-form-datepicker-wrapper'
                                    className='search-form-date-input'
                                    selected={isoToDate(searchParams.arrivalDate)}
                                    onChange={(v)=>setSearchParams({...searchParams, arrivalDate: dateToIso(v)})}
                                    placeholderText='Arrival date'
                                    dateFormat="dd/MM/yyyy" />
                        <DatePicker wrapperClassName='search-form-datepicker-wrapper'
                                    className='search-form-date-input'
                                    selected={isoToDate(searchParams.departureDate)}
                                    onChange={(v)=>setSearchParams({...searchParams, departureDate: dateToIso(v)})}
                                    placeholderText='Departure date'
                                    dateFormat="dd/MM/yyyy" />
                        <ToggleSwitch className='search-form-toggle-input'
                                      checked={searchParams.checkedIn}
                                      setChecked={(x) => setSearchParams({...searchParams, checkedIn: x})}/>
                    </div>
                </div>
                <div className='search-group-row actions'>
                    <div className='search-group-elems'>
                        <button className={"guest-search-button" + (isFormValid() ? "": " disabled")} type="submit" disabled={!isFormValid()} onClick={()=>setView('results')}>Search</button>
                        <button className="guest-search-button" type="submit" onClick={clear}>Clear</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default SearchGuestForm;