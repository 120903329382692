import React from 'react';
import './DownloadButton.css';

const DownloadButton = ({ onClick, title }) => {
  return (
    <div className="download-button" onClick={onClick} title={title}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="var(--text-color-invert)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="download-icon"
      >
        <path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="var(--text-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" stroke="var(--text-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </div>
  );
};

export default DownloadButton;



