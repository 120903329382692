import React from 'react';
import { Tooltip } from 'react-tooltip';

const AmanTooltip = ({ id, pos, text }) => {
  return (
    <React.Fragment>
        <span className="tooltip-icon" 
            data-tooltip-id={id}
            data-tooltip-html={text}
            data-tooltip-place={pos}>
        ?
        
        </span>
        <Tooltip id={id}/>
    </React.Fragment>
  );
};

export default AmanTooltip;
