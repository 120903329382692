import React, { useState } from 'react';
import HeadingFrame from '../HeadingFrame/HeadingFrame';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import './SearchHotelForm.css';
import { isoToDate, dateToIso  } from '../../utils/utilities';
import { useMsal } from '@azure/msal-react';
import { callApiWithToken, downloadExcelWithToken } from '../../utils/fetch';
import { useQuery } from '@tanstack/react-query';

const SearchHotelForm = ({searchParams, setSearchParams, setView, clearSearch, isDesktop}) => {

    const { instance } = useMsal();
    const isFormValid = () => (
        searchParams.property.trim() !== '' &&
        searchParams.startDate.trim() !== '' &&
        searchParams.endDate.trim() !== ''
        )
    const isDateRangeValid = () => {
        if(searchParams.startDate === '' || searchParams.endDate === ''){
            return false
        }
        return isoToDate(searchParams.startDate) <= isoToDate(searchParams.endDate)
    }
    const [downloading, setDownloading] = useState(false);

    const downloadExcel = () => {
        setDownloading(true);
        downloadExcelWithToken(instance, `/arrival-report/download?${new URLSearchParams(searchParams)}`).then((res)=>{
            setDownloading(false);
            return res;
        }).catch(()=>{
            setDownloading(false);
            alert("Issue downloading report")
        })
    }
    const fetchFilterData = () => callApiWithToken(instance,'/snowflake/filter-data')
    .then((res) => res );
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['filter-data'], () => fetchFilterData(), { keepPreviousData : true, staleTime: 2*60*60*1000 })
    
    let propertiesData = data?.PROFILE_PROPERTY?.map((x)=>{ return {value: x.PROPERTY_CODE, label: x.PROPERTY_NAME}});

    return(
        <React.Fragment>
            <HeadingFrame>
                Arrival Report
            </HeadingFrame>
            <div className='hotel-search-form-wrapper'>
                <div className='search-group-row'>
                    <div className='search-group-title'>Generate arrival report</div>
                    <div className='search-group-elems'>
                        <Select
                            placeholder={"Choose hotel"}
                            value={searchParams.property == '' ? '' : propertiesData?.filter((x)=>searchParams.property==x.value)[0]}
                            onChange={(e)=>{let val = e ? e.value:""; setSearchParams({...searchParams, property: val})}}
                            options={propertiesData}
                            noOptionsMessage={({inputValue}) => `No results for ${inputValue}`}
                            isClearable
                            escapeClearsValue
                            backspaceRemovesValue
                            isSearchable
                            className='search-form-select-input'
                            classNamePrefix="search-form-select-input"    
                        />
                    </div>
                    <div className='search-group-elems'>
                        <DatePicker wrapperClassName='search-form-datepicker-wrapper'
                                    className='search-form-date-input'
                                    selected={isoToDate(searchParams.startDate)}
                                    onChange={(v)=>setSearchParams({...searchParams, startDate: dateToIso(v)})}
                                    placeholderText='Start date'
                                    dateFormat="dd/MM/yyyy" />
                        <DatePicker wrapperClassName='search-form-datepicker-wrapper'
                                    className='search-form-date-input'
                                    selected={isoToDate(searchParams.endDate)}
                                    onChange={(v)=>setSearchParams({...searchParams, endDate: dateToIso(v)})}
                                    placeholderText='End date'
                                    dateFormat="dd/MM/yyyy" />
                    </div>
                    {! isDateRangeValid() && isFormValid() &&
                        <div className='search-group-elems' style={{color: "#CE0000"}}>
                            Start date must be earlier than end date
                        </div>
                    }
                </div>
                <div className='search-group-row actions'>
                    <div className='search-group-elems arrival-report-buttons'>
                        {isDesktop &&
                        <button className={"guest-search-button" + (isFormValid() && isDateRangeValid() ? "": " disabled")} 
                                type="submit"
                                disabled={!isFormValid() || !isDateRangeValid()}
                                onClick={()=>setView('results')}>
                                    View Report
                        </button>
                        }
                        <button className={"guest-search-button" + (isFormValid() && isDateRangeValid() && !downloading ? "": " disabled")} 
                                type="submit"
                                disabled={!isFormValid() || !isDateRangeValid() || downloading }
                                onClick={downloadExcel}
                                >
                                    {downloading ? "Downloading ... ": "Download Report"}
                        </button>
                        <button className="guest-search-button" type="submit" onClick={clearSearch}>Clear</button>
                    </div>
                </div>
            </div>  
        </React.Fragment>
    )
}
export default SearchHotelForm;