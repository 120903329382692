import React from 'react';
import PropTypes from 'prop-types';

import './SearchModal.css';

const SearchModal = ({ onClose, children }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="search-modal-overlay" onClick={handleOverlayClick}>
      <div className="search-modal-content">
        <span className="search-modal-close-button" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};

SearchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default SearchModal;



// const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

// onClick={() => setIsSearchModalOpen(true)}

// {isSearchModalOpen && (
//   <SearchModal onClose={() => setIsSearchModalOpen(false)}>
//     Inner component
//   </SearchModal>
// )}