import React, { useState } from 'react';
import '../GuestProfileDesktop.css'
import { callApiWithToken } from '../../../utils/fetch';
import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
const PersonalPreferences = ({guest}) => {
    const { instance } = useMsal();
    const fetchPrefs = () => callApiWithToken(instance, `/user/${guest.USER_ID}/other`).then((res)=>res);
    
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['user_other',guest.USER_ID], () => fetchPrefs(), { keepPreviousData : true, staleTime: 2*60*1000 })
    let roomPrefs = data?.RoomsPreferences;
    let fbPrefs = data ? data["F&bPreferences"] : undefined;
    let spaPrefs = data?.SPAPreferences;
    let retailPrefs = data?.RetailPreferences;
    
    // Remove the following 4 lines ones the backend sends back real data
    roomPrefs = null;
    fbPrefs = null;
    spaPrefs = null;
    retailPrefs = null;
    const roomPreferences = () => {
        if(isError) {
            return "Error retrieving room preferences"
        } else if (! roomPrefs || roomPrefs.length == 0) {
            return "N/A"
        } else {
            return roomPrefs.join(", ");
        }
    }    
    const fbPreferences = () => {
        if(isError) {
            return "Error retrieving F&B preferences"
        } else if (! fbPrefs || fbPrefs.length == 0) {
            return "N/A"
        } else {
            return fbPrefs.join(", ");
        }
    }
    const spaPreferences = () => {
        if(isError) {
            return "Error retrieving spa preferences"
        } else if (! spaPrefs || spaPrefs.length == 0) {
            return "N/A"
        } else {
            return spaPrefs.join(", ");
        }
    }
    const retPreferences = () => {
        if(isError) {
            return "Error retrieving retail preferences"
        } else if (! retailPrefs || retailPrefs.length == 0) {
            return "N/A"
        } else {
            return retailPrefs.join(", ");
        }
    }
    const renderPreference = (callback) => {
        if(isFetching || isLoading ) {
            return(
                <div className="loader-container pref-loader-container">
                    <span className="loader pref-loader"></span>
                </div>)
        } else {
            return callback()
        }
    }
    return (
        <div className='left-col-box-wrapper'>
            <div className='left-col-box-title'>
                Personal preferences
            </div>
            <div className='left-col-box-item'>
                <div className='label'>Rooms</div>
                <div>{renderPreference(roomPreferences)}</div>
            </div>
            <div className='left-col-box-item'>
                <div className='label'>F&B</div>
                <div>{renderPreference(fbPreferences)}</div>
            </div>
            <div className='left-col-box-item'>
                <div className='label'>SPA</div>
                <div>{renderPreference(spaPreferences)}</div>
            </div>
            { /* ---UNCOMMENT IF THIS INFORMATION IS NEEDED (As was not in the designs)
            <div className='left-col-box-item'>
                <div className='label'>Retail</div>
                <div>{renderPreference(retPreferences)}</div>
            </div>
        */}
        </div>
    )
}
export default PersonalPreferences;



