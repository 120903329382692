import React, { useState } from 'react';
import '../GuestProfileDesktop.css'
import { callApiWithToken } from '../../../utils/fetch';
import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import AmanTooltip from '../../General/AmanTooltip/AmanTooltip';
const GuestValue = ({guest}) => {
    const { instance } = useMsal();
    const fetchVal = () => callApiWithToken(instance, `/user/${guest.USER_ID}/value`).then((res)=>res);
    
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['user_value',guest.USER_ID], () => fetchVal(), { keepPreviousData : true, staleTime: 2*60*1000 })
    
    const renderData = (value) => {
        return isFetching || isLoading ? (
            <div className='loader-container' style={{height: "22px"}}><div className='loader' style={{height: "20px", width: "20px"}}></div></div>
        ):isError ? (
            "Error retrieving data"
        ):(!value || value.length == 0) ?("N/A"):(value)
    }

    const nightPerStay = () => {
        return isFetching || isLoading ? (
            <div className='loader-container' style={{height: "22px"}}><div className='loader' style={{height: "20px", width: "20px"}}></div></div>
        ):isError ? (
            "Error retrieving data"
        ):data.visitedHotelsCount > 0 && data.nightsCount > 0 ? (
            Math.round(parseFloat(data.nightsCount)*100 / parseFloat(data.visitedHotelsCount))/100
        ):("N/A")
    }

    const spendPerStay = () => {
        return isFetching || isLoading ? (
            <div className='loader-container' style={{height: "22px"}}><div className='loader' style={{height: "20px", width: "20px"}}></div></div>
        ):isError ? (
            "Error retrieving data"
        ):data.lifetimeValue > 0 && data.visitedHotelsCount > 0 ? (
            Math.round(parseFloat(data.lifetimeValue)*100 / parseFloat(data.visitedHotelsCount))/100
        ):("N/A")
    }

    const spendPerNight = () => {
        return isFetching || isLoading ? (
            <div className='loader-container' style={{height: "22px"}}><div className='loader' style={{height: "20px", width: "20px"}}></div></div>
        ):isError ? (
            "Error retrieving data"
        ):data.lifetimeValue > 0 && data.nightsCount > 0 ? (
            Math.round(parseFloat(data.lifetimeValue)*100 / parseFloat(data.nightsCount))/100
        ):("N/A")
    }

    return (
        <div className='profile-value-box'>
        <div className='title'>
            Value
        </div>
        <div className='content-wrapper'>
            <div className='content'>
                <div className='label'>
                    Lifetime value<AmanTooltip id="ltv2-tootlip" pos="top" text="Customer lifetime value (CLV) measures the total income Aman can expect from the guest."/>
                </div>
                <div className='value'>
                    ${renderData(data?.lifetimeValue)}
                </div>
            </div>

            <div className='content'>
                <div className='label'>
                    Stays
                </div>
                <div className='value'>
                    {renderData(data?.visitedHotelsCount)}
                </div>
            </div>


            <div className='content'>
                <div className='label'>
                    Avg/Stay
                </div>
                <div className='value'>
                    ${spendPerStay()}
                </div>
            </div>


            <div className='content'>
                <div className='label'>
                    Total nights
                </div>
                <div className='value'>
                    {renderData(data?.nightsCount)}
                </div>
            </div>

            <div className='content'>
                <div className='label'>
                    Nights/Stay
                </div>
                <div className='value'>
                    {nightPerStay()}
                </div>
            </div>

            <div className='content'>
                <div className='label'>
                    Avg/Night
                </div>
                <div className='value'>
                    ${spendPerNight()}
                </div>
            </div>
        </div>
    </div>
    )
}
export default GuestValue;



