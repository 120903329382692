import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react';
import {Container} from 'react-bootstrap';
import {loginRequest, silentRequest} from "../auth/authConfig";
import {useEffect, useState} from "react";
import {HomePageText} from '../languages/en'
import * as Sentry from "@sentry/browser";

// Components
import {NavBar, Footer, LoginImageSwitch} from "./Sections";
import { AppButton, Spacer} from "./General";

import amanLogo from '../assets/images/aman_group_logo.png';

import {} from '../components/UserDetails'
// Styles

export const PageLayout = (props) => {

    const {instance} = useMsal();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!instance.getActiveAccount()) {
            if (instance.getAllAccounts().length > 0) {
                instance.acquireTokenSilent(silentRequest).then(loginUser).catch((error) => {
                    setLoading(false);
                });
            } else {
                instance.ssoSilent(silentRequest).then(loginUser).catch((error) => {
                    setLoading(false);
                });
            }
        } else {
            const account = instance.getActiveAccount();
            Sentry.setUser({ username: account.username })
            setLoading(false);
        }
    }, [instance])

    const  loginUser = (response) => {
        instance.setActiveAccount(response.account);
        sessionStorage.setItem("idToken", response.idToken);
        Sentry.setUser({username: response.account.username})
        setLoading(false);
    }

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    if (loading) {
        return null;
    }

    return (
        <>
            <AuthenticatedTemplate>
                {/* <NavBar/> */}
                {props.children}
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Container className="d-flex flex-column justify-content-between align-items-center" style={{ minHeight: '100vh', overflow: 'clip', maxWidth: '100%' }}>
                    <div className="d-flex justify-content-center p-4" style={{ margin: 'auto' }}>
                        <img src={amanLogo} style={{width: "200px"}}/>
                    </div>
                    <div className="d-block justify-content-center" style={{ width: '95vw', margin: 'auto', marginTop: '0' }}>
                        <div className="d-block text-center" style={{ width: '100%' }}>
                            <h1 className="my-2 p-1">Guest experience</h1>
                        </div>
                        <div className="d-flex justify-content-center p-10 mx-auto my-4" style={{ width: '100%' }}>
                            <AppButton onClick={handleLoginRedirect}>{HomePageText.loginButtonText}</AppButton>
                        </div>
                    </div>
                    <Footer />
                </Container>
            </UnauthenticatedTemplate>

        </>
    );
}