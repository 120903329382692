import "./GuestProfileTabLayout.css"
import React, {useState} from "react";
import {GuestPage_Tabs} from "../../../languages/en.jsx";
import GuestPageInfoTab from "./info/GuestPageInfoTab.jsx";
import GuestPageStayTab from "./stay/GuestPageStayTab.jsx";
import GuestPageValueTab from "./value/GuestPageValueTab.jsx";
const GuestProfileTabLayout = ({guest}) => {
    const [activeTab, setActiveTab] = useState('Information');
    const tabHeaders = [
        { label: GuestPage_Tabs.tab_header_info, component: <GuestPageInfoTab guest = {guest} /> },
        { label: GuestPage_Tabs.tab_header_stays, component: <GuestPageStayTab userId = {guest.USER_ID} /> },
        { label: GuestPage_Tabs.tab_header_value, component: <GuestPageValueTab userId = {guest.USER_ID} /> },
    ];

    const handleTabClick = (label) => {
        setActiveTab(label);
    };
    return (
        <>
            <div className="guest-profile-tabs-wrapper">
                    <div className="guest-profile-tabs-selector-row">
                        <div className="guest-profile-tabs-selector">
                            <div className="guest-profile-tabs-selector-wrapper">
                                {tabHeaders.map((tab) => (
                                    <div
                                        key={tab.label}
                                        className={`guest-profile-tabs-selector-btn ${activeTab === tab.label ? 'active' : ''}`}
                                        onClick={() => handleTabClick(tab.label)}
                                    >
                                        {tab.label}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="guest-profile-tabs-content-row">
                        <div className="guest-profile-tabs-content">
                            {tabHeaders.map((tab) => (
                                <div
                                    key={tab.label}
                                    className={`guest-profile-tab guest-360-tab-${tab.label.toLowerCase()}`}
                                    style={{ display: activeTab === tab.label ? 'block' : 'none' }}
                                >
                                    <div className={`guest-profile-tab-${tab.label.toLowerCase()}-wrapper guest-profile-tab-wrapper`}>
                                        {tab.component}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

        </>
    )
}
export default GuestProfileTabLayout;