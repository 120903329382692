import React from 'react';
import './info/GuestPageInfoTab.css';
import AmanTooltip from '../../General/AmanTooltip/AmanTooltip';

const MobileKeyValueRowItem = ({ label, value }) => {
    const getTooltip = (key) => {
        if(key == "Marketing Consent") {
            return <AmanTooltip id="mktconsent-tootlip" pos="top" text="Consent given by guest to receive<br/>marketing communications"/>
        } else if(key == "Lifetime value") {
            return <AmanTooltip id="ltv1-tootlip" pos="top" text="Customer lifetime value (CLV)<br/>measures the total income Aman can expect<br/>from the guest."/>
        }return null
    }
    return (
        <div className="div-row">
            <div className="key">{label}{getTooltip(label)}</div>
            <div className="value">{value}</div>
        </div>
    );
};

export default MobileKeyValueRowItem;
