import React, { useState } from 'react';
import '../GuestProfile.css'
import { useMsal } from '@azure/msal-react';
import { callApiWithToken } from '../../../utils/fetch';
import { useQuery } from '@tanstack/react-query';
import { datePrettyDDMMYYY} from '../../../utils/utilities';
import { GuestValuePieChart } from '../../UserDetails/InnerComponents';
import AmanTooltip from '../../General/AmanTooltip/AmanTooltip';

const DesktopTransactions = ({guest}) => {
    const tabOptions = {
        All: 0,
        Rooms: 1,
        FB: 2,
        Spa: 3,
        Retail: 4,
        Other: 5
    }

    const [tab, setTab] = useState(tabOptions.All)

    const { instance } = useMsal();
    const fetchVal = () => callApiWithToken(instance, `/user/${guest.USER_ID}/value`).then((res)=>res);
    
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['user_value',guest.USER_ID], () => fetchVal(), { keepPreviousData : true, staleTime: 2*60*1000 })
    
    const renderData = (value) => {
        return isFetching || isLoading ? (
            <div className='loader-container' style={{height: "22px"}}><div className='loader' style={{height: "20px", width: "20px"}}></div></div>
        ):isError ? (
            "Error retrieving data"
        ):(!value || value.length == 0) ?("N/A"):(value)
    }

    const performTabFilter = (val) => {
        switch(tab) {
            case tabOptions.Rooms:
                return val.category == "rooms";
            case tabOptions.FB:
                return val.category == "f&b";
            case tabOptions.Spa:
                return val.category == "spa";
            case tabOptions.Retail:
                return val.category == "retail";
            case tabOptions.Other:
                return val.category == "other";
            default:
                return true
        };
    }

    return isFetching || isLoading ? (
        <div className='loader-container'><div className='loader'></div></div>
    ): isError ?(
        <div>Error {error.message}</div>
    ):
    (
        <React.Fragment>
        <div className='desktop-transactions-box'>
            <div className='transactions-summary'>
                <div className='next-stay-details'>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            Lifetime value<AmanTooltip id="ltv1-tootlip" pos="top" text="Customer lifetime value (CLV) measures the total income Aman can expect from the guest."/>
                        </div>
                        <div className='detail-value'>
                            ${renderData(data.lifetimeValue)}
                        </div>
                    </div>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            Nights total
                        </div>
                        <div className='detail-value'>
                            {renderData(data.nightsCount)}
                        </div>
                    </div>
                    <div className='next-stay-detail-item'>
                        <div className='detail-label'>
                            Hotels visited
                        </div>
                        <div className='detail-value'>
                            {renderData(data.visitedHotelsCount)}
                        </div>
                    </div>
                </div>
                <div className='transaction-tabs-wrapper'>
                    <div className='tab-selection'>
                        <div className={`clickable-smalltab-label ${tabOptions.All === tab ? " selected":""}`} onClick={()=>setTab(tabOptions.All)}>
                            All
                        </div>
                        <div className={`clickable-smalltab-label ${tabOptions.Rooms === tab ? " selected":""}`} onClick={()=>setTab(tabOptions.Rooms)}>
                            Rooms
                        </div>
                        <div className={`clickable-smalltab-label ${tabOptions.FB === tab ? " selected":""}`} onClick={()=>setTab(tabOptions.FB)}>
                            F&B
                        </div>
                        <div className={`clickable-smalltab-label ${tabOptions.Spa === tab ? " selected":""}`} onClick={()=>setTab(tabOptions.Spa)}>
                            Spa
                        </div>
                        <div className={`clickable-smalltab-label ${tabOptions.Retail === tab ? " selected":""}`} onClick={()=>setTab(tabOptions.Retail)}>
                            Retail
                        </div>
                        <div className={`clickable-smalltab-label ${tabOptions.Other === tab ? " selected":""}`} onClick={()=>setTab(tabOptions.Other)}>
                            Other
                        </div>
                    </div>
                    

                </div>                
                <div className='all-stays-header'>
                    {tab == tabOptions.All && <div className='header-label'>
                        Category
                    </div>}
                    <div className='header-label'>
                        Hotel
                    </div>
                    <div className='header-label'>
                        Detail
                    </div>
                    <div className='header-label'>
                        Date
                    </div>
                    <div className='header-label'>
                        Total value
                    </div>
                </div>
                <div className='all-stay-scrollable-wrapper'>
                {data.all.filter(performTabFilter).map((row, index) =>
                    <div className='all-stay-row' key={index}>
                        {tab == tabOptions.All && <div className='row-item'>
                            {row.category}
                        </div>}
                        <div className='row-item'>
                            {row.hotel}
                        </div>
                        <div className='row-item'>
                            {row.detail}
                        </div>
                        <div className='row-item'>
                            {datePrettyDDMMYYY(row.date)}
                        </div>
                        <div className='row-item'>
                            ${row.totalValue}
                        </div>
                    </div>                
                )}
            </div>
            </div>
            <div className='transactions-charts'>
                <GuestValuePieChart  spending={data.all} />
            </div>
        </div>
        </React.Fragment>
    )
}
export default DesktopTransactions;