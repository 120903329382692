const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
// Get timezone offset in ISO format (+hh:mm or -hh:mm)
const getTimezoneOffset = date => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  return diff + pad(tzOffset / 60) + ':' + pad(tzOffset % 60);
};

const toISOStringWithTimezone = date => {
  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    getTimezoneOffset(date);
};

export const dateToIso = (date) => {
    return date ? toISOStringWithTimezone(date).split('T')[0] : ''; // Handle null date
  };
export const isoToDate = (value) => {
    return value ? new Date(value) : value;
}
export const cap = (value) => {
  if(value) {
    let out = value.toLowerCase();
    return out.charAt(0).toUpperCase() + out.slice(1);
  } else {
    return value
  }
}

export const truncate = (text,size) => {
  if(text?.length > size){
      return `${text.slice(0,size)}...`
  } else {
      return text;
  }
}

export const languageRender = (value) => {
  if(value && value.length > 0) {
    switch(value) {
      case 'E': return 'English'
      case 'J': return 'Japanese'
      default: return value
    }
  } else {
    return "N/A"
  }
}
export const nationalityRender = (value) => {
  if(value && value.length > 0) {
    return value
  } else {
    return "N/A"
  }
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const datePretty = (value) => {
  if(value && value.length > 0) {
    let d = isoToDate(value);
    let month = months[d.getMonth()];
    let day = d.getDate(); 
    let year = d.getFullYear();
    return `${month}, ${day} ${year}`
  } else {
    return "N/A"
  }
}
export const datePrettyDDMMYYY = (value) => {
  if(value && value.length > 0) {
    let d = isoToDate(value);
    let month = months[d.getMonth()];
    let day = d.getDate();
    let year = d.getFullYear();
    return `${day} ${month} ${year}`
  } else {
    return "N/A"
  }
}

export const countryRender = (value) => {
  if(value && value.length > 0) {
    return value
  } else {
    return "N/A"
  }
}

export const displayNAIfNull = (value) => {
  return value ?? 'N/A';
};

export const displayRate = (value) => {
  return value !== null && value !== undefined ? `$${value}` : 'N/A';
};