import React from 'react';

import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const footerText = `Copyright ${currentYear}, Aman Group S.a.r.l.`;

  return <footer className="footer"><div><p className="footer">{footerText}</p></div></footer>;
};

export default Footer;
