import React, { useState } from 'react';
import {useMsal} from "@azure/msal-react";
import {callApiWithToken, downloadExcelWithToken} from '../../utils/fetch'
import { useQuery } from '@tanstack/react-query'
import {
    getCoreRowModel,
    flexRender,
    useReactTable,
  } from '@tanstack/react-table'

import HeadingFrame from '../HeadingFrame/HeadingFrame';
import { sha256 } from 'js-sha256';
import './SearchHotelResults.css';
import DownloadButton from "../General/DownloadButton/DownloadButton.jsx";

const SearchHotelResults = ({searchParams, reset, totalCount, setCount}) => {
    const { instance } = useMsal();
    const [page, setPage] = useState(1)
    const rowsPerPage = 7;
    const queryString = `/arrival-report/report?${new URLSearchParams(searchParams)}`;
    const fetchArrivals = (page = 1) => callApiWithToken(instance,`${queryString}&page=${page}&pageSize=${rowsPerPage}`).then((res) => {if(res.TOTAL_COUNT!==null){setCount(res.TOTAL_COUNT)};return res});
    const hash = () => sha256(JSON.stringify(searchParams));
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['arrivals_view', hash(), page], () => fetchArrivals(page), { keepPreviousData : true, staleTime: 2*60*1000 })


    const [downloading, setDownloading] = useState(false);
    const downloadExcel = () => {
        setDownloading(true);
        downloadExcelWithToken(instance, `/arrival-report/download?${new URLSearchParams(searchParams)}`).then((res)=>{
            setDownloading(false);
            return res;
        }).catch(()=>{
            setDownloading(false);
            alert("Issue downloading report")
        })
    }
      const columns = [
        {
            accessorFn: row => row.firstName,
            id: 'firstName',
            cell: info => info.getValue(),
            header: () => "First name",
            footer: props => props.column.id,
        },
        {
            accessorFn: row => row.lastName,
            id: 'lastName',
            cell: info => info.getValue(),
            header: () => "Last name",
            footer: props => props.column.id,
        },
        {
            accessorFn: row => row.birthday,
            id: 'birthday',
            cell: info => info.getValue(),
            header: () => "Birth date",
            footer: props => props.column.id,
        },
        {
            accessorFn: row => row.vipCode,
            id: 'vipCode',
            cell: info => info.getValue(),
            header: () => "VIP code",
            footer: props => props.column.id,
        },
        {
            accessorFn: row => row.arrivalDate,
            id: 'arrivalDate',
            cell: info => info.getValue(),
            header: () => "Arr. date",
            footer: props => props.column.id,
        },
        {
            accessorFn: row => row.rateCode,
            id: 'rateCode',
            cell: info => info.getValue(),
            header: () => "Rate code",
            footer: props => props.column.id,
        },
        {
            accessorFn: row => row.pmsReservationNumber,
            id: 'pmsReservationNumber',
            cell: info => info.getValue(),
            header: () => "Res. number",
            footer: props => props.column.id,
        },
        {
            accessorFn: row => row.nationality,
            id: 'nationality',
            cell: info => info.getValue(),
            header: () => "Nationality",
            footer: props => props.column.id,
        }
    ]

    const table = useReactTable({
        data: data?.ARRIVAL_REPORT_DATA ? data?.ARRIVAL_REPORT_DATA : [],
        columns,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        debugTable: false,
        debugHeaders: false,
        debugColumns: false,
      })

    return(
        <React.Fragment>
            <HeadingFrame >
               <div className={"arrival-report-header"} >
                   <div className='guest-profile-desktop-chevron-icon arrival-report-header-chevron' onClick={reset}></div>
                   <div>Search Results</div>
                   <DownloadButton className={"arrival-report-download-button " + (!downloading ? "": " disabled")} title={downloading ? "Downloading ... ": "Download Report"} onClick={downloadExcel}/>
               </div>
            </HeadingFrame>
            <div className="guest-results-table-wrapper">
                <div className="h-2" />
                <table className="w-full">
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{ position: 'relative', width: header.getSize() }}
                                            >
                                                {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                    )}
                                                {false && header.column.getCanResize() && (
                                                    <div
                                                    onMouseDown={header.getResizeHandler()}
                                                    onTouchStart={header.getResizeHandler()}
                                                    className={`resizer ${
                                                    header.column.getIsResizing() ? 'isResizing' : ''
                                                    }`}
                                                    ></div>
                                                )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id} onClick={()=>setGuest(row.original)}>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td key={cell.id} style={{ width: cell.column.getSize() }}>
                                                {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="h-4" />
            </div>
            <div className='results-controls'>
                <button className={`pagination-button ${page === 1 ? 'disabled' : ''}`} onClick={() => setPage(old => Math.max(old - 1, 1))} disabled={page === 1}>
                    Prev
                </button>
                <span className="pagination-info">
                {isFetching ? (
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
                ):(`${page} / ${Math.ceil(totalCount/rowsPerPage)}`)}
                </span>
                <button className={`pagination-button ${isPreviousData || page >= Math.ceil(totalCount/rowsPerPage) ? 'disabled' : ''}`} onClick={() => {
                                if (!isPreviousData) {
                                    setPage(old => old + 1)
                                }
                                }} disabled={isPreviousData || page >= Math.ceil(totalCount/rowsPerPage) }>
                    Next
                </button>
            </div>

        </React.Fragment>)
}
export default SearchHotelResults;