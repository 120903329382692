import React from 'react';
import {useMsal} from '@azure/msal-react';
import { Link } from 'react-router-dom'
import {loginRequest} from '../../../auth/authConfig';
import { NavBarText } from '../../../languages/en'
import amanLogo from '../../../assets/images/aman_group_logo.png'

import './NavBar.css'
import { cap } from '../../../utils/utilities';

const NavBar = ({ dynamicText }) => {
    const {instance} = useMsal();
    const activeAccount = instance.getActiveAccount();

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        localStorage.removeItem("idToken");
        instance.logoutRedirect().catch((error) => console.log(error));
    };

    const displayRole = () => {
      let role = activeAccount?.idTokenClaims?.roles || NavBarText.permissionText;
      role = typeof role == 'string' ? role : role.join();
      return cap(role.replace("_"," "))
    }

  return (
    <div className="navbar-container">
      <div className="navbar">
        <div className="navbar-left">
           <Link to="/"> 
            <img src={amanLogo} className='aman-logo-navbar'/> 
           </Link> 
        </div>
        <div className="navbar-right">
          <ul>
            <li className="dropdown">
              {activeAccount ? (
              <a href="#" className="dropbtn">
                {activeAccount.name} 
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 9l6 6 6-6" />
                </svg>

              </a>
              ) : null}
              <div className="dropdown-content">
                <div className='dropdown-role-display'>{displayRole()}</div>
                <a className="dropdown-link" href="#"
                  onClick={activeAccount ? handleLogoutRedirect : handleLoginRedirect}
                  >
                    {activeAccount ? NavBarText.navbar_logout : NavBarText.navbar_login}
                </a>

              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;


// <NavBar dynamicText='Hotels & Resorts' />
