export const NavBarText = {
    navbar_login: 'Log in',
    navbar_logout: 'Log out',
    permissionText: 'Hotels & Resorts',
};

export const NoRoleText = {
    explanation: 'You are not authorized to access this application.'
}

export const HomePageText = {
    imageAltText: 'Welcome to the Aman 360 customer view',

    homePageHeading: 'Aman Group',
    homePageSubHeading: 'Guest 360 View',
    loginButtonText: 'Log in',

    NextGuestText: 'GO TO\nTRANSACTIONAL CAMPAIGN',
    Guest360Text: 'GO TO\nAMAN GUEST 360',
    ReportText: 'GENERATE\nARRIVAL REPORT',

    ArrivalReportHeading: 'Generate Arrival Report',

    ArrivalReportNotification: 'Please fill in all of the fields below to enable the search to download',

    ArrivalReportHotelsHeader: 'Hotels',
    ArrivalReportHotelsDefaultText: 'Select hotel property',

    ArrivalReportCheckedInHeader: 'Checked In',
    ArrivalReportCheckedInOptionOne: 'Yes',
    ArrivalReportCheckedInOptionTwo: 'No',
    
    ArrivalReportArrivalHeader: 'Check in start',
    ArrivalReportDepartureHeader: 'Check in end',

    DepartureDateLessThanArrivalDate: 'Departure date must be after arrival date',

    ArrivalReportButtonText: 'Generate Arrival Report',

    ArrivalReportErrorApiText: 'Regrettably, data retrieval from the Snowflake Data Warehouse is currently unavailable due to an API error.',
};

export const SearchPageText = {
    SearchFilterHeading: 'Search Aman Guests',
    SearchNotification: 'Please fill in one of the fields below to enable the search',
    FirstNameHeader: 'First name',
    FirstNamePlaceholder: 'Enter first name',
    LastNameHeader: 'Last name',
    LastNamePlaceholder: 'Enter last name',
    EmailHeader: 'Email',
    EmailPlaceholder: 'Enter email',
    PhoneHeader: 'Phone number',
    PhonePlaceholder: 'Enter phone number',
    DOBHeader: 'Birth date',
    CountryHeader: 'Country',
    CountryDefaultText: 'Select country',
    VIPHeader: 'VIP code',
    VIPDefaultText: 'Select VIP code',
    HotelsHeader: 'Hotels',
    HotelsDefaultText: 'Select hotel property',

    dropdownDefaultText: 'No options available',

    CheckedInHeader: 'Checked In',
    CheckedInOptionOne: 'Yes',
    CheckedInOptionTwo: 'No',
    ArrivalHeader: 'Arrival',
    DepartureHeader: 'Departure',
    KeywordHeader: 'Keyword',
    KeywordPlaceholder: 'Enter keyword to search',
    ButtonText: 'Filter Search',
    ClearFilter: 'Clear',

    errorApiText: 'Regrettably, data retrieval from the Snowflake Data Warehouse is currently unavailable due to an API error.',
};


export const SearchResultsPageText = {
    searchButtonTooltip: 'New search for Aman Guests',
    downloadTooltip: 'Download search table',

    resultsTable_header_title: 'Title',
    resultsTable_header_firstname: 'First Name',
    resultsTable_header_lastname: 'Last name',
    resultsTable_header_email: 'Email',
    resultsTable_header_phone: 'Phone',
    resultsTable_header_vip: 'VIP code',
    resultsTable_header_rfmd: 'RFMD',
    resultsTable_header_city: 'City',
    resultsTable_header_country: 'Country',

    noGuestsFoundText: 'No guest data matches your search criteria.\nPlease try your search again.',
};

export const GuestPage_Summary = {
    toolTipText_guestImage: 'Public image of the Guest',
    
    satisfactionText: 'Satisfaction',
    toolTipText_satisfactionText: 'Average score of all reviews received',

    ProfileCompletionText: 'Profile completion',
    toolTipText_completionScore: 'Data quality score',

    toolTiptext_highlightedValue1: 'Tags referring to Customer category and potential',
    toolTiptext_highlightedValue2: 'Tags referring to Customer category and potential',
    toolTiptext_highlightedValue3: 'Tags referring to Customer category and potential',

    toolTipDescriptionText_language: 'Language',
    toolTipText_language: 'Preferred communication language',
    
    descriptionText_dob: 'Date of birth',

    descriptionText_nationality: 'Nationality',
    
    toolTipDescriptionText_ORSID: 'ORS ID',
    toolTipText_ORSID: 'Guest unique Opera ID',

    toolTipDescriptionText_joined: 'Joined',
    toolTipText_joined: 'Date in which the Guest has first been registered in the Aman database',

    toolTipDescriptionText_RFMDTag: 'RFMD Tag',
    tooltipText_RFMDTag: 'Quantitatif segmentation calculated by the system. Based on Recency, Frequency, Monetary and Duration.',
    
    toolTipDescriptionText_VIPcode: 'VIP Code',
    tooltipText_VIPcode: 'Qualitatif segmentation. It can be changed by Hotel. Please refer to SOP',
    
    toolTipDescriptionText_WxScore: 'WealthX Score',
    tooltipText_WxScore: 'External guest qualification based on Wealth',
    
    descriptionText_LastStay: 'Last stay',
    
    toolTipDescriptionText_MarketingConsent: 'Marketing consent',
    tooltipText_MarketingConsent: 'Consent given by guest to receive marketing communications',
    
    toolTipDescriptionText_LTV: 'Lifetime value',
    tooltipText_LTV: 'Customer lifetime value (CLV) measures the total income Aman can expect from the guest.',

    Modal_SatisfactionHeading: 'Guest Satisfaction Score',
    satisfactionModal_header_checkin: 'Check in',
    satisfactionModal_header_checkout: 'Check out',
    satisfactionModal_header_hotel: 'Hotel',
    satisfactionModal_header_rating: 'Rating',
    satisfactionModal_header_guestComment: 'Guest comment',
};

export const GuestPage_Tabs = {
    tab_header_info: 'Information',
    tab_header_stays: 'Stays',
    tab_header_value: 'Transactions',
    tab_header_other: 'Other',
};

export const GuestPage_Tab_Information = {
    descriptionText_Phone1: 'Phone 1',
    descriptionText_Phone2: 'Phone 2',
    descriptionText_PA: 'PA',
    descriptionText_Email1: 'Email 1',
    descriptionText_Email2: 'Email 2',
    descriptionText_Address: 'Address',
    descriptionText_ZipCode: 'Zip Code',
    descriptionText_City: 'City',
    descriptionText_Country: 'Country',
    descriptionText_Work: 'Work',
    descriptionText_Position: 'Position',
    descriptionText_Newsletter: 'Newsletter',
};

export const GuestPage_Tab_Stays = {
    NextStay: 'Next Stay',

    descriptionText_CheckIn: 'Check in',
    descriptionText_CheckOut: 'Check out',

    descriptionText_Hotel: 'Hotel',
    descriptionText_CN: 'Confirmation Number',
    descriptionText_Status: 'Status',

    descriptionText_Guests: 'Guests',
    descriptionText_Rate: 'Rate',
    descriptionText_ADR: 'ADR',

    toolTipDescriptionText_MostVisitedProp: 'Most visited property',
    tooltipText_MostVisitedProp: 'Calculated field on most visited Hotel',

    AllStays: 'All Stays',

    staysTable_header_checkin: 'Check in',
    staysTable_header_checkout: 'Check out',
    staysTable_header_property: 'Property',
    staysTable_header_nights: 'Nights',
    staysTable_header_resNo: 'Reservation No.',
    staysTable_header_rate: 'Rate',
};


export const GuestPage_Tab_Value = {
    toolTipDescriptionText_LTV: 'Lifetime value',
    tooltipText_LTV: 'Customer lifetime value (CLV) measures the total income Aman can expect from the guest.',
    
    descriptionText_TNoN: 'Total number of nights',

    descriptionText_TNoHV: 'Total number of hotels visited',

    valueTable_tab_name_all: 'ALL',
    valueTable_tab_name_rooms: 'ROOMS',
    valueTable_tab_name_fnb: 'F&B',
    valueTable_tab_name_spa: 'SPA',
    valueTable_tab_name_retail: 'RETAIL',
    valueTable_tab_name_other: 'OTHER',
    
    valueTable_header_category: 'Category',
    valueTable_header_hotel: 'Hotel',
    valueTable_header_detail: 'Detail',
    valueTable_header_date: 'Date',
    valueTable_header_totVal: 'Total value',
};

export const GuestPage_Tab_Other = {
    descriptionText_RoomPrefs: 'Rooms preferences',

    descriptionText_FnBPrefs: 'F&B preferences',

    descriptionText_SpaPrefs: 'Spa preferences',

    descriptionText_RetailPrefs: 'Retail Preferences',
};

export const ArrivalResultsPageText = {
    searchButtonTooltip: 'New search for Arrival Report',
    downloadTooltip: 'Download Arrival Report',

    arrivalReportTable_header_guest_image: 'Guest Image',
    arrivalReportTable_header_firstname: 'First name',
    arrivalReportTable_header_lastname: 'Last name',
    arrivalReportTable_header_dob: 'Birthday',
    arrivalReportTable_header_vip: 'VIP code',
    arrivalReportTable_header_rfmd: 'RFMD',
    arrivalReportTable_header_nationality: 'Nationality',
    arrivalReportTable_header_arrival: 'Arrival Date',
    arrivalReportTable_header_departure: 'Departure Date',
    arrivalReportTable_header_rate: 'Rate Code',
    arrivalReportTable_header_revenue: 'Stay Revenue',
    arrivalReportTable_header_res_no: 'Reservation No.',
    arrivalReportTable_header_ors_id: 'ORS ID',
    arrivalReportTable_header_pax: 'Pax',
    arrivalReportTable_header_wealthx: 'Wealth-X',
    arrivalReportTable_header_review_score: 'Latest Review',
    arrivalReportTable_header_all_stays: 'All Stays',
    arrivalReportTable_header_total_stays: 'Total Stays',
    arrivalReportTable_header_total_spend: 'Total Spend',
    arrivalReportTable_header_central_comment: 'Central Comment',

    noGuestsFoundText: 'No guest data matches your search criteria.\nPlease try your search again.',

    arrivalReportExcel_filename: 'Arrival Report',
    arrivalReportExcel_sheet: 'Arrivals'
};