import React, { useState } from 'react';
import SearchHotelForm from '../../components/SearchHotelForm/SearchHotelForm';
import SearchHotelResults from '../../components/SearchHotelResults/SearchHotelResults';
import { useMediaQuery } from 'react-responsive';

const initialView = "search";
const initialSearchParams = {
    property:'',
    startDate: '',
    endDate: ''
}

const Hotel = () => {
    const isDesktop = useMediaQuery({
        query: '(min-width: 900px)'
    })
    const [searchParams, setSearchParams] = useState(initialSearchParams);
    const [totalCount, setCount] = useState(0);
    const [view, setView] = useState(initialView);
    
    const showSearch = () => isDesktop ? view === "search" : true;

    const clearSearch = () => {
        setSearchParams(initialSearchParams);
        setCount(0);
    }

    return(
        <React.Fragment>
            {showSearch() && <SearchHotelForm searchParams={searchParams}
                                              setSearchParams={setSearchParams}
                                              setView={setView}
                                              clearSearch={clearSearch}
                                              isDesktop={isDesktop}/>}
            {!showSearch() && <SearchHotelResults searchParams={searchParams}
                                                  totalCount={totalCount}
                                                  setCount={setCount}
                                                  reset={()=>setView(initialView)}/>}
        </React.Fragment>
    )
}
export default Hotel;