import React from 'react';
import PropTypes from 'prop-types';
import './SatisfactionModal.css';

const SatisfactionModal = ({ onClose, children }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="satisfaction-modal-overlay" onClick={handleOverlayClick}>
      <div className="satisfaction-modal-content">
        <span className="satisfaction-modal-close-button" onClick={onClose}>&times;</span>
        {children}
      </div>
    </div>
  );
};

SatisfactionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default SatisfactionModal;
