import React from 'react';

import './FilterHeading.css';

const FilterHeading = ({ text = 'Filter heading' }) => {
  return (
    <h2 className="filter-heading">{text}</h2>
  );
};

export default FilterHeading;


//<FilterHeading text="Filters" />