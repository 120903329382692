import React, { useState } from 'react';
import {SearchPageText} from '../../../languages/en'

import './FilterDropdown.css';

const FilterDropdown = ({ options, defaultText, value, onChange }) => {

    const handleOptionChange = (event) => {
        const newValue = event.target.value;
        //setSelectedOption(newValue);
        onChange(newValue);
    };

    const selectedClass = value ? 'selected' : '';

    return (
        <div className={`filter-dropdown ${selectedClass}`}>
            <select
                value={value}
                onChange={handleOptionChange}
                className="filter-button"
            >
                <option value="">{defaultText}</option>
                {options && options.length > 0 ? (
                    options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))
                ) : (
                    <option value="" disabled>
                        {SearchPageText.dropdownDefaultText}
                    </option>
                )}
            </select>
        </div>
    );
};

export default FilterDropdown;



// const options = [
//         { value: 'option1', label: 'Option 1' },
//         { value: 'option2', label: 'Option 2' },
//         { value: 'option3', label: 'Option 3' },
//     ];

// <FilterDropdown options={options} defaultText="Dropdown"/>



