import React, { useState } from 'react';
import {useMsal} from "@azure/msal-react";
import {callApiWithToken} from '../../utils/fetch'
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import '../General/Loader/Loader.css'

import './SearchGuestResults.css'
import GuestMobileRow from './GuestMobileRow';

import { sha256 } from 'js-sha256';
import SearchWidget from './SearchWidget';

const GuestResultsMobile = ({searchParams, setSearchParams, setView, totalCount, setCount, setGuest}) => {
    const { instance } = useMsal();
    const [expendedUser, setExpandedUser] = useState(null);
    const rowsPerPage = 10;
    const queryString = `/snowflake?${new URLSearchParams(searchParams)}`;
    const hash = () => sha256(JSON.stringify(searchParams));
    const fetchUsers = ({pageParam}) => 
        callApiWithToken(instance,`${queryString}&page=${pageParam}&pageSize=${rowsPerPage}`).then((res) => {if(res.USER_COUNT>0){setCount(res.USER_COUNT)};return res.USER_DATA});
    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
      } = useInfiniteQuery({
        queryKey:['usersMobile',hash()],
        queryFn: fetchUsers,
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
      })

      const hasNext = ()=>{let x = totalCount > data?.pageParams.length * rowsPerPage/*;debugger*/;return x;}

      return status === 'loading' ? (
        <div className='mobile-row-loader'><span className='loader'></span></div>
      ) : status === 'error' ? (
        <p>Error: {error.message}</p>
      ) : (
        <>

          <div className="mobile-search-widget">
            <SearchWidget searchParams={searchParams} setSearchParams={setSearchParams} setView={setView}/>
          </div>
            <div className="infinite-list-container">
                {data.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.map(user => (
                            <GuestMobileRow
                                key={user.USER_ID}
                                user={user}
                                setExpandedUser={setExpandedUser}
                                expandedUser={expendedUser == user.USER_ID}
                                setGuest={setGuest}/>
                        ))}
                    </React.Fragment>
                ))}

                <div className='guest-result-mobile-controls'>
                    {!isFetchingNextPage && (
                        <p>
                            {hasNext() ? (
                                <button
                                    className='guest-result-mobile-button'
                                    onClick={() =>
                                        fetchNextPage({pageParam: data.pageParams[-1] ? data.pageParams[-1] + 1 : 2})
                                    }
                                    disabled={isFetchingNextPage}
                                >
                                    Load More
                                </button>
                            ) : (
                                '...'
                            )}
                        </p>
                    )}
                    {isFetchingNextPage && (
                        <p>Loading more...</p>
                    )}
                    {(isFetching || isFetchingNextPage) && (
                        <div className='mobile-row-loader'><span className='loader'></span></div>
                    )}
                </div>

            </div>
        </>
      )
}
export default GuestResultsMobile;























