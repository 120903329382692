import React from 'react';

import './LandingButton.css';

const LandingButton = ({onClick, backgroundImage, text, bgPosMobile}) => {
  return (
    <div className="landing-button-wrapper" onClick={onClick}>
      <div className='landing-button-image' style={{backgroundImage: `url(${backgroundImage})`, backgroundPositionY: bgPosMobile}}>
        <img src={backgroundImage}/>
      </div>
      <div className='landing-button-footer'>
        <div className='landing-button-text'>
          {text}
        </div>
        <div className='landing-button-control'>
        </div>
      </div>
    </div>
  );
};

export default LandingButton;
