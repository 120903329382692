import React, { useState } from 'react';
import {Rating,Star} from '@smastrom/react-rating'
import '@smastrom/react-rating/style.css'
import './GuestProfileDesktop.css'
import DesktopStays from './DesktopTabs/DesktopStays';
import DesktopTransactions from './DesktopTabs/DesktopTransactions';
import PersonalPreferences from './DesktopTabs/PersonalPreferences';
import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { callApiWithToken } from '../../utils/fetch';
import {
    cap,
    countryRender,
    datePrettyDDMMYYY,
    languageRender,
    nationalityRender,
    truncate
} from '../../utils/utilities';
import GuestValue from './DesktopTabs/GuestValue';
import guestImage from "../../assets/images/guest-image.png";
import { SatisfactionModal, SatisfactionModalContent } from '../../components/UserDetails/InnerComponents';
import Truncator from '../General/Truncator/Truncator';
import AmanTooltip from '../General/AmanTooltip/AmanTooltip';

const GuestProfileDesktop = ({guest, resetGuest}) => {
    const { instance } = useMsal();
    const [tab, setTab] = useState("stays");
    const [satis, setSatis] = useState(false);

    const isStays = () => tab == "stays";
    const isTransactions = () => tab == "transactions";
    const setStays = () => setTab("stays");
    const setTransactions = () => setTab("transactions");

    const renderTabContent = () =>{
        if(isStays()){
            return(<DesktopStays guest={guest}/>)
        } else if (isTransactions()) {
            return(<DesktopTransactions guest={guest}/>)
        }
    }

    const fetchInfos = () => callApiWithToken(instance, `/user/${guest.USER_ID}/info`).then((res)=>res);
    
    const {
        isLoading,
        isError,
        error,
        data,
        isFetching,
        isPreviousData,
      } = useQuery(['user_info',guest.USER_ID], () => fetchInfos(), { keepPreviousData : true, staleTime: 2*60*1000 })
    return isFetching || isLoading ? (
        <div className='loader-container loading-profile-container'><div className='loader'></div></div>
    ):isError ? (
        <div>Error: {error.message}</div>
    ):(
        <div className='profile-desktop-wrapper'>
            {satis && <SatisfactionModal onClose={()=>setSatis(false)} children={<SatisfactionModalContent/>}/>}
            <div className='profile-desktop-top-wrapper'>
                <div className='guest-profile-desktop-chevron-icon' onClick={resetGuest}></div>
                <div className='profile-desktop-picture'>
                    <img src={guestImage}/>
                </div>
                <div className='profile-desktop-pii-top'>
                    <div className='profile-desktop-name'>{data.title ? `${data.title} ` : ""}{`${data.firstName} ${data.lastName}`}</div>
                    <div className='profile-desktop-top-details'>
                        {/*<span className='bit-bolder'>Aman Group&nbsp;&nbsp;</span>&nbsp;Global Head of CRM & Business Intelligence*/}
                        
                    <div className='tags'>
                        {data.tags?.map((tag,index)=> 
                            <div className='tag' key={index}>{tag}</div>)
                        }
                    </div>
                    </div>
                    <div className='profile-desktop-top-contact'>
                        <span className='label'>Phone:&nbsp;</span> {`${!data.phone1 && !data.phone2 ? "N/A" : data.phone1 && data.phone2 ? `${truncate(data.phone1,15)}, ${truncate(data.phone2,15)}`:data.phone1 ? truncate(data.phone1,15) : truncate(data.phone2,15)} `}
                        <span className='label padded'>Email:&nbsp;</span> {!data.email1 && !data.email2 ? "N/A" : data.email1 && data.email2 ? (<Truncator text={data.email1} length={30}/>, <Truncator text={data.email2} length={30}/>):data.email1 ? <Truncator text={data.email1} length={30}/> : <Truncator text={data.email2} length={30}/>}
                    </div>
                </div>
                <div className='profile-desktop-tags'>

                </div>
            </div>

            <div className='profile-desktop-main-container'>
                <div className='profile-desktop-left'>
                    <PersonalPreferences guest={guest}/>

                    <div className='left-col-box-wrapper'>
                        <div className='left-col-box-title'>
                            Personal information
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>
                                    Language<AmanTooltip id="language-tooltip" pos="top" text="Preferred communication language"/>
                            </div>
                            <div>{languageRender(data.language)}</div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>Nationality</div>
                            <div>{nationalityRender(data.nationality)}</div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>Birth date</div>
                            <div>{datePrettyDDMMYYY(data.dateOfBirth)}</div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>ORS ID<AmanTooltip id="orsid-tootlip" pos="top" text="Guest unique Opera ID"/></div>
                            <div><Truncator text={data.orsId.join(',')} length={24}/></div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>VIP code<AmanTooltip id="vipcode-tootlip" pos="top" text="Qualitatif segmentation. It can be changed by Hotel. Please refer to SOP"/></div>
                            <div>{guest.GUEST_VIP_CODE}</div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>Join Date<AmanTooltip id="joindedate-tootlip" pos="top" text="Date in which the Guest has first been registered in the Aman database"/></div>
                            <div>{datePrettyDDMMYYY(data.joinDate)}</div>
                        </div>
                    </div>

                    <div className='left-col-box-wrapper'>
                        <div className='left-col-box-title'>
                            Address
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>Country</div>
                            <div>{countryRender(data.country)}</div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>City</div>
                            <div>{data.city ? data.city : "N/A"}</div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>Street</div>
                            <div><Truncator text={data.address ? data.address: "N/A"} length={30}/></div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>Zip code</div>
                            <div>{data.zipCode ? data.zipCode : "N/A"}</div>
                        </div>
                    </div>

                    <div className='left-col-box-wrapper'>
                        <div className='left-col-box-title'>
                            Newsletters
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>Marketing consent<AmanTooltip id="mktconsent-tootlip" pos="top" text="Consent given by guest to receive marketing communications"/></div>
                            <div>{data.marketingConsent ? "Yes" : "No"}</div>
                        </div>
                        <div className='left-col-box-item'>
                            <div className='label'>Newsletters</div>
                            <div>{data.newsletter ? data.newsletter.join(", ") : "N/A"}</div>
                        </div>
                    </div>

                </div>
                <div className='profile-desktop-right'>
                    <GuestValue guest={guest}/>

                    <div className='tab-box'>
                        <div className='tab-selection'>
                            <div className={`clickable-tab-label ${isStays() ? " selected":""}`} onClick={setStays}>
                                Stays
                            </div>
                            <div className={`clickable-tab-label ${isTransactions() ? " selected":""}`} onClick={setTransactions}>
                                Transactions
                            </div>
                        </div>
                        {renderTabContent()}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GuestProfileDesktop;